import { createSlice } from "@reduxjs/toolkit";
import { AuthProps } from "./auth";

const initialState: AuthProps.State = {
  isAuthenticated: false,
  forgotPasswordStep: "forgot",
  resetFormValue: { email: "", otp: "" },

  getStatusAPI_STATUS: "idle",
  loginAPI_STATUS: "idle",
  logoutAPI_STATUS: "idle",
  forgotPasswordAPI_STATUS: "idle",
  registerAPI_STATUS: "idle",
  resetPasswordAPI_STATUS: "idle",
  checkOtpAPI_STATUS: "idle",
  resendOtpAPI_STATUS: "idle",
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setAuthState: (state, action: AuthProps.setAuthState) => {
      state.isAuthenticated = action.payload.authenticated;
    },
    setForgotPasswordStep: (state, action: AuthProps.setForgotPasswordStep) => {
      state.forgotPasswordStep = action.payload;
    },
    setResetFormValue: (state, action: AuthProps.setResetFormValue) => {
      state.resetFormValue = {
        email: action.payload.email || state.resetFormValue.email,
        otp: action.payload.otp || state.resetFormValue.otp,
      };
    },

    setGetStatusAPI_STATUS: (
      state,
      action: AuthProps.setGetStatusAPI_STATUS
    ) => {
      state.getStatusAPI_STATUS = action.payload;
    },
    setLoginAPI_STATUS: (state, action: AuthProps.setLoginAPI_STATUS) => {
      state.loginAPI_STATUS = action.payload;
    },
    setLogoutAPI_STATUS: (state, action: AuthProps.setLogoutAPI_STATUS) => {
      state.logoutAPI_STATUS = action.payload;
    },
    setRegisterAPI_STATUS: (state, action: AuthProps.setRegisterAPI_STATUS) => {
      state.registerAPI_STATUS = action.payload;
    },
    setForgotPasswordAPI_STATUS: (
      state,
      action: AuthProps.setForgotPasswordAPI_STATUS
    ) => {
      state.forgotPasswordAPI_STATUS = action.payload;
    },
    setResetPasswordAPI_STATUS: (
      state,
      action: AuthProps.setResetPasswordAPI_STATUS
    ) => {
      state.resetPasswordAPI_STATUS = action.payload;
    },
    setCheckOtpAPI_STATUS: (state, action: AuthProps.setCheckOtpAPI_STATUS) => {
      state.checkOtpAPI_STATUS = action.payload;
    },
    setResendOtpAPI_STATUS: (
      state,
      action: AuthProps.setResendOtpAPI_STATUS
    ) => {
      state.resendOtpAPI_STATUS = action.payload;
    },

    reset: () => initialState,
  },
});

export const authActions = authSlice.actions;
export const authReducer = authSlice.reducer;
