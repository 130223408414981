import styled from "styled-components";
import { Components } from "@thisgorilla/primate-ds";

import { ImageUploadProps } from "./ImageUpload";
import { useAppSelector } from "../../../../../../../redux";
import { API_CALLS } from "../../../../../../../API/api_calls";

const SingleImagePreview = ({
  fileInputRef,
  setFileUploadShow,
}: ImageUploadProps.SingleImagePreview) => {
  const { uploadedImagesUrls, uploadedImages, post_id } = useAppSelector(
    (state) => state.posts
  );
  return (
    <Container>
      <Image src={uploadedImagesUrls[0]} />
      <ButtonContainer>
        <Components.Button
          color="On-Primary"
          disabled={false}
          kind="Primary"
          label="Add images/videos"
          onClick={() => fileInputRef?.current?.click()}
          size="Small"
          iconPosition="left"
          iconType="PlusCircle"
        />
        <CloseButton>
          <Components.IconButton
            disabled={false}
            iconType="X"
            kind="Secondary"
            onClick={() => {
              API_CALLS.POSTS.deleteAnImageForAPost(
                {
                  image_id: uploadedImages[0].id,
                  post_id,
                },
                setFileUploadShow
              );
            }}
            size="Small"
          />
        </CloseButton>
      </ButtonContainer>
    </Container>
  );
};

export default SingleImagePreview;

const Container = styled.div`
  position: relative;
  border-radius: ${({ theme }) => theme.radius["radius-xs"].value}px;
  border: 1px solid
    ${({ theme }) => theme.Color.border["ui-border-neutral"].value};
`;

const Image = styled.img`
  width: 100%;
  display: block;
  border-radius: ${({ theme }) => theme.radius["radius-xs"].value}px;
`;

const ButtonContainer = styled.div`
  position: absolute;
  display: flex;
  gap: ${({ theme }) => theme.spacing["spacing-2"].value}px;
  top: ${({ theme }) => theme.spacing["spacing-2"].value}px;
  right: ${({ theme }) => theme.spacing["spacing-2"].value}px;
`;

const CloseButton = styled.div`
  background: ${({ theme }) =>
    theme.Color.elevation.surface["surface-overlay"].value};
  border-radius: ${({ theme }) => theme.radius["radius-xxs"].value}px;
`;
