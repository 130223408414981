import { KeyboardEvent, useEffect, useState } from "react";
import { useTheme } from "styled-components";
import { Formik } from "formik";
import { Components } from "@thisgorilla/primate-ds";
import { NavLink, useNavigate } from "react-router-dom";

import Input from "../../Input";
import { Icons } from "../../icons";
import AuthLayout from "../AuthLayout";
import { AuthCommonStyle } from "../style";
import { loginValidationSchema } from "./utils";
import { ROUTES } from "../../../utills/routes";
import { API_PATHS } from "../../../API/api_paths";
import { actions, useAppDispatch, useAppSelector } from "../../../redux";
import { API_CALLS } from "../../../API/api_calls";
import { AuthProps } from "../../../redux/slices/auth/auth";
import { useRedirectUrl } from "../hooks/useRedirectUrl";
import { useAuthRedirect } from "../hooks/useAuthRedirect";

const Login = () => {
  const dispatch = useAppDispatch();
  const { loginAPI_STATUS } = useAppSelector((state) => state.auth);

  const { Color, typography } = useTheme();
  const navigate = useNavigate();

  const [isPasswordShow, setIsPasswordShow] = useState(false);
  const [submitClicked, setSubmitClicked] = useState(false);

  const redirectURL = useRedirectUrl();
  const onSubmit = (values: AuthProps.LoginRequest) => {
    API_CALLS.AUTH.login(values, redirectURL, navigate);
  };

  useEffect(() => {
    dispatch(actions.auth.setLoginAPI_STATUS("idle"));
  }, [dispatch]);
  useAuthRedirect();

  const initialValues: AuthProps.LoginRequest = { email: "", password: "" };

  return (
    <AuthLayout>
      <AuthCommonStyle.Wrapper>
        <Components.Text
          style={{
            tokenStyle: typography.Heading.L,
          }}
          text="Sign in"
          fontWeight="font-weight-semibold"
        />

        <AuthCommonStyle.Container>
          <AuthCommonStyle.SocialButton
            onClick={() =>
              window.open(API_PATHS.Authentication.socialAuthGoogle, "_self")
            }
          >
            <Icons.Google />
            <Components.Text
              style={{
                tokenStyle: typography.Body.L,
              }}
              text="Continue with Google"
            />
          </AuthCommonStyle.SocialButton>
          <AuthCommonStyle.SocialButton
            onClick={() =>
              window.open(API_PATHS.Authentication.socialAuthApple, "_self")
            }
          >
            <Icons.Apple />
            <Components.Text
              style={{ tokenStyle: typography.Body.L }}
              text="Continue with Apple"
            />
          </AuthCommonStyle.SocialButton>
        </AuthCommonStyle.Container>
      </AuthCommonStyle.Wrapper>
      <AuthCommonStyle.InfoContainer>
        <AuthCommonStyle.Divider />
        <Components.Text
          style={{ tokenStyle: typography.Body.M }}
          text="or Sign in with Email"
          color={Color.text["text-tertiary"].value}
        />
        <AuthCommonStyle.Divider />
      </AuthCommonStyle.InfoContainer>
      <Formik
        initialValues={initialValues}
        validationSchema={loginValidationSchema}
        onSubmit={onSubmit}
      >
        {(formik) => {
          const onKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
            const isEnterKey = e.key === "Enter";
            if (isEnterKey) formik.submitForm();
          };
          return (
            <AuthCommonStyle.Wrapper>
              <AuthCommonStyle.GroupContainer>
                <AuthCommonStyle.GroupContainer>
                  <Input
                    fieldName="email"
                    placeholder="Email"
                    isRequired
                    idPrefix="login"
                    onKeyDown={onKeyDown}
                  />
                  <Input
                    fieldName="password"
                    placeholder="Password"
                    isRequired
                    idPrefix="login"
                    type={isPasswordShow ? "text" : "password"}
                    iconClickhandler={() => setIsPasswordShow(!isPasswordShow)}
                    onKeyDown={onKeyDown}
                  />
                </AuthCommonStyle.GroupContainer>

                <NavLink
                  to={ROUTES.forgotPassword}
                  style={{ textDecoration: "none", width: "fit-content" }}
                >
                  <Components.Text
                    style={{ tokenStyle: typography.Body.M }}
                    text="Forgot password?"
                    color={Color.text["text-emphasize-brand"].value}
                  />
                </NavLink>
              </AuthCommonStyle.GroupContainer>
              <AuthCommonStyle.GroupContainer>
                <Components.Button
                  color="Primary"
                  kind="Primary"
                  label="Sign in"
                  size="Large"
                  width="fill"
                  onClick={() => {
                    setSubmitClicked(true);
                    formik.isValid && formik.submitForm();
                  }}
                  disabled={
                    (submitClicked && !formik.isValid) ||
                    loginAPI_STATUS === "progress" ||
                    Object.keys(formik.errors).length > 0
                  }
                />
                <div style={{ textAlign: "center" }}>
                  <Components.Text
                    style={{ tokenStyle: typography.Body.L }}
                    text="Not registered? "
                  />
                  <NavLink to={ROUTES.register}>
                    <Components.Text
                      style={{ tokenStyle: typography.Body.L }}
                      text="Sign up"
                      color={Color.text["text-emphasize-brand"].value}
                      isunderlined
                    />
                  </NavLink>
                </div>
              </AuthCommonStyle.GroupContainer>
            </AuthCommonStyle.Wrapper>
          );
        }}
      </Formik>
    </AuthLayout>
  );
};

export default Login;
