import styled, { useTheme } from "styled-components";
import { Components } from "@thisgorilla/primate-ds";

import { MeProps } from "../../../redux/slices/me/Me";

const UserInfo = (props: MeProps.UserWithFollowersMetadata) => {
  const { Color, typography } = useTheme();
  const { profile_picture, first_name, last_name, headline } = props;
  return (
    <InfoContainer>
      <Components.Avatar
        disabled={false}
        size="Small-40"
        src={profile_picture}
        type="Circle"
      />
      <NameContainer>
        <Components.Text
          text={first_name + " " + last_name}
          maxLineLength={1}
          style={{
            tokenStyle: {
              ...typography.Heading.XXS,
              "line-height": "16px",
            },
          }}
          fontWeight="font-weight-semibold"
        />
        <Components.Text
          text={headline}
          maxLineLength={1}
          style={{
            tokenStyle: typography.Body.S,
          }}
          color={Color.text["text-secondary"].value}
        />
      </NameContainer>
    </InfoContainer>
  );
};

export default UserInfo;

const InfoContainer = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing["spacing-2"].value}px;
`;
const NameContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
