import { handleError } from "../errors";
import store, { actions } from "../../redux";
import { API_INSTANCES } from "../api_instance";
import { PollsProps } from "../../redux/slices/polls/Polls";

const dispatch = store.dispatch;

const createANewPoll = (data: PollsProps.CreateANewPollPayload) =>
  API_INSTANCES.POLLS.createANewPoll(data)
    .then((response) => {
      const data = response.data as PollsProps.CreateANewPollResponse;
      dispatch(actions.polls.setCreateANewPollResponse(data));
    })
    .catch((error) => handleError(error, createANewPoll.name));

const showAPoll = (poll_id: string) =>
  API_INSTANCES.POLLS.showAPoll(poll_id)
    .then((response) => {
      const data = response.data as PollsProps.ShowAPollResponse;
      console.log(data);
    })
    .catch((error) => handleError(error, showAPoll.name));

export const POLLS = { createANewPoll, showAPoll };
