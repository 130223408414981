import styled from "styled-components";
import { Components, ComponentsProps } from "@thisgorilla/primate-ds";

import { API_CALLS } from "../../../../../../API/api_calls";
import { useAppSelector } from "../../../../../../redux";
import { CreatePostModalProps } from "../CreatePostModal";

const ModalFooter = ({
  urlData,
  value,
  setCreatePostOpen,
}: CreatePostModalProps.ModalFooter) => {
  const { post_id } = useAppSelector((state) => state.posts);

  const footerLeftSideData: {
    iconType: ComponentsProps.IconButtonProps.IProps["iconType"];
    onClick: () => void;
  }[] = [
    { iconType: "Image", onClick: () => {} },
    { iconType: "Gif", onClick: () => {} },
    { iconType: "Smile", onClick: () => {} },
    { iconType: "Poll", onClick: () => {} },
    { iconType: "Slider", onClick: () => {} },
    { iconType: "FileText", onClick: () => {} },
  ];

  const handlePost = () => {
    API_CALLS.POSTS.activateAPost(
      {
        content: value.html,
        delivery_date: new Date().toISOString(),
        node_tree: JSON.stringify(value.state?.toJSON()),
        post_id,
        topics: [],
        url_id: urlData?.id ?? null,
      },
      setCreatePostOpen
    );
  };

  return (
    <ModalFooterContainer>
      <FooterLeftSide>
        {footerLeftSideData.map(({ iconType, onClick }, index) => (
          <Components.IconButton
            iconType={iconType}
            onClick={onClick}
            disabled={false}
            kind="Secondary"
            size="Medium"
            key={index}
          />
        ))}
      </FooterLeftSide>
      <FooterRightSide>
        <Components.Button
          color="Neutral"
          kind="Secondary"
          disabled={false}
          label="Topics"
          onClick={() => {}}
          size="Medium"
          iconPosition="left"
          iconType="PlusCircle"
        />
        <Components.IconButton
          iconType="Calendar"
          onClick={() => {}}
          disabled={false}
          kind="Secondary"
          size="Medium"
        />
        <Components.Button
          color="Primary"
          kind="Primary"
          disabled={false}
          label="Post"
          onClick={handlePost}
          size="Medium"
        />
      </FooterRightSide>
    </ModalFooterContainer>
  );
};

export default ModalFooter;

const ModalFooterContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: ${({ theme }) => theme.spacing["spacing-4"].value}px;
`;

const FooterLeftSide = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing["spacing-2"].value}px;
`;
const FooterRightSide = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing["spacing-4"].value}px;
`;
