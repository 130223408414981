import { Components, tokens } from "@thisgorilla/primate-ds";
import styled from "styled-components";
import { strengthWiseBackground, strengthWisePercentage } from "./utils";

export type Strength = "weak" | "average" | "strong";

export const PasswordStrengthMeter = ({ strength }: { strength: Strength }) => {
  return (
    <Container>
      <div>
        <ProgressContainer>
          <StrengthContainer $strength={strength} />
        </ProgressContainer>
      </div>
      <Components.Text
        text={strength}
        style={{ tokenStyle: tokens.desktop_typography.Body.M }}
        color={
          strength === "weak"
            ? tokens.light.text["text-emphasize-negative"].value
            : strength === "average"
            ? tokens.light.text["text-emphasize-warm"].value
            : tokens.light.text["text-emphasize-positive"].value
        }
      />
    </Container>
  );
};

const ProgressContainer = styled.div`
  height: 4px;
  background: ${({ theme }) => theme.Color.background.neutral.default.value};
  border-radius: 4px;
  width: 100%;
`;

const StrengthContainer = styled.div<{
  $strength: Strength;
}>`
  height: 4px;
  background: ${({ $strength }) => strengthWiseBackground($strength)};
  border-radius: ${({ $strength }) =>
    strengthWisePercentage($strength) === 100 ? "4px" : "4px 0px 0px 4px"};
  width: ${({ $strength }) => strengthWisePercentage($strength)}%;
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 0 37.5px;
  & > div {
    width: 100%;
  }
  & > label {
    text-transform: capitalize;
  }
`;
