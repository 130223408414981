import styled from "styled-components";

const PageContainer = styled.div`
  padding: 64px 56px 110px 64px;
  max-width: 1440px;
  margin: 0 auto;
`;

const AuthContainer = styled.div`
  background: url(/images/auth/gorilla-driving-harley.png) no-repeat;
  background-size: 890px 756px;
  height: 756px;
  border-radius: ${({ theme }) => theme.radius["radius-xl"].value}px;
`;
const AuthWrapper = styled.div`
  display: flex;
  width: 480px;
  min-width: 320px;
  padding: ${({ theme }) => theme.spacing["spacing-16"].value}px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  float: right;
  height: 100%;
  gap: 31px;
  border-radius: ${({ theme }) => theme.radius["radius-xl"].value}px;
  border: 1px solid
    ${({ theme }) => theme.Color.border["ui-border-subtle"].value};
  background: ${({ theme }) =>
    theme.Color.elevation.surface["surface-primary"].value};
  box-shadow: 0px 4px 20px 0px rgba(205, 205, 205, 0.16);
`;
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${({ theme }) => theme.spacing["spacing-10"].value}px;
  width: 100%;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing["spacing-4"].value}px;
  width: 100%;
`;

const SocialButton = styled.div`
  padding: ${({ theme }) => theme.spacing["spacing-2"].value}px;
  display: flex;
  gap: ${({ theme }) => theme.spacing["spacing-2"].value}px;
  align-items: center;
  justify-content: center;
  border: 1px solid
    ${({ theme }) => theme.Color.border["ui-border-subtle"].value};
  border-radius: ${({ theme }) => theme.radius["radius-xxs"].value}px;
  cursor: pointer;

  &:hover {
    background: rgba(30, 150, 242, 0.08);
    border-color: ${({ theme }) =>
      theme.Color.border["ui-border-neutral"].value};
  }
`;

const InfoContainer = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing["spacing-2"].value}px;
  align-items: center;
  width: 100%;
`;

const Divider = styled.div`
  height: 1px;
  flex: 1;
  background: ${({ theme }) => theme.Color.border["ui-border-neutral"].value};
`;

const GroupContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing["spacing-2"].value}px;
  width: 100%;
`;

export const AuthCommonStyle = {
  PageContainer,
  AuthContainer,
  AuthWrapper,
  Wrapper,
  Container,
  SocialButton,
  Divider,
  InfoContainer,
  GroupContainer,
};
