import * as Yup from "yup";

export const createPollValidation = (
  choiceCount: number[],
  required: boolean
) => {
  let schemaShape: {
    [key: string]: Yup.StringSchema | Yup.NumberSchema;
  } = {};
  for (let i = 1; i <= choiceCount.length; i++) {
    schemaShape[`choice_${choiceCount[i - 1]}`] = required
      ? Yup.string().required(`Please enter Choice ${choiceCount[i - 1]}`)
      : Yup.string();
  }
  schemaShape.duration = required
    ? Yup.number().required("Please select Duration")
    : Yup.number();

  schemaShape.content = required
    ? Yup.string()
    : Yup.string().required("Please Enter Post content");

  schemaShape.question = required
    ? Yup.string().required("Please Enter Poll question")
    : Yup.string();
  return Yup.object(schemaShape);
};

export const durationOption = [
  { label: "1 Day", value: 1 },
  { label: "1 Week", value: 7 },
  { label: "2 Weeks", value: 14 },
];

export const CHOICE_COUNT_INITIAL_VALUE = [1, 2, 3];
export const RICHTEXT_INITIAL_VALUE =
  '<p class="PlaygroundEditorTheme__paragraph"><br></p>';
