import { useMemo } from "react";
import { Components } from "@thisgorilla/primate-ds";
import styled, { useTheme } from "styled-components";

import { useAppSelector } from "../../../../../redux";
import {
  addProxyUrlToProfileImage,
  getPeopleNamesText,
} from "../../../../../utils";

export const AvatarGroupAndDescription = () => {
  const { typography } = useTheme();
  const { peopleYouMightKnow } = useAppSelector((state) => state.me);

  //#region Data
  const AvatarGroupSrc = useMemo(() => {
    if (!peopleYouMightKnow || !peopleYouMightKnow.length) return [];

    const AvatarGroupSrc = peopleYouMightKnow.map((people) =>
      addProxyUrlToProfileImage(people.profile_picture)
    );

    return AvatarGroupSrc;
  }, [peopleYouMightKnow]);

  const peopleYouMightKnowText = useMemo(() => {
    return getPeopleNamesText(peopleYouMightKnow);
  }, [peopleYouMightKnow]);
  //#endregion

  return (
    <Container>
      {AvatarGroupSrc.length > 0 && (
        <Components.AvatarGroup
          size="XSmall-28"
          src={AvatarGroupSrc}
          srcCountToBeRendered={3}
        />
      )}
      <Components.Text
        text={peopleYouMightKnowText}
        style={{ tokenStyle: typography.Body.S }}
        maxLineLength={2}
      />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
`;
