const baseUrl = process.env.REACT_APP_GORRILA_API;

//Authentication
const login = baseUrl + "/auth";
const logout = baseUrl + "/auth";
const checkOtp = baseUrl + "/auth/check-otp";
const forgotPassword = baseUrl + "/auth/forgot-password";
const registerANewUser = baseUrl + "/auth/register";
const resendOtp = baseUrl + "/auth/resend-otp";
const resetPassword = baseUrl + "/auth/reset-password";
const getStatus = baseUrl + "/auth/status";
const socialAuthGoogle = baseUrl + "/auth/google";
const socialAuthApple = baseUrl + "/auth/apple";
const Authentication = {
  login,
  logout,
  checkOtp,
  forgotPassword,
  registerANewUser,
  resendOtp,
  resetPassword,
  getStatus,
  socialAuthGoogle,
  socialAuthApple,
};

//Me
const getUserInformation = baseUrl + "/me";
const getPeopleYouMightKnow = baseUrl + "/me/discover";
const getYourInnerCircle = baseUrl + "/me/inner-circle";
const getRecommendedJobs = baseUrl + "/me/jobs";
const getMentions = baseUrl + "/me/mentions";
const getSuggestedUsers = baseUrl + "/me/suggested";
const getAllUsers = baseUrl + "/me/users";
const me = {
  getUserInformation,
  getPeopleYouMightKnow,
  getYourInnerCircle,
  getRecommendedJobs,
  getMentions,
  getSuggestedUsers,
  getAllUsers,
};

//Connections
const getConnections = baseUrl + "users/connections";
const followAUser = baseUrl + "/users/follow";
const getFollowCounts = baseUrl + "/follow/counts";
const getFollowingUsers = baseUrl + "/users/following";
const unfollowAUser = baseUrl + "/users/unfollow";
const connections = {
  getConnections,
  followAUser,
  getFollowCounts,
  getFollowingUsers,
  unfollowAUser,
};

//Saved Items
const getSavedItemsCounts = baseUrl + "/saved_items";
const createSavedItem = baseUrl + "/saved_items";
const deleteSavedItem = baseUrl + "/saved_items";
const savedItems = {
  getSavedItemsCounts,
  createSavedItem,
  deleteSavedItem,
};

//posts
const post = baseUrl + "/posts/";
const userPosts = baseUrl + "/user/posts/";
const repostAPost = baseUrl + "/posts/repost";
const createPostDraft = baseUrl + "/posts/draft";
const activateAPost = baseUrl + "/posts/activate";
const posts = {
  post,
  userPosts,
  repostAPost,
  createPostDraft,
  activateAPost,
};

export const API_PATHS = {
  Authentication,
  me,
  connections,
  savedItems,
  posts,
};
