import { useTheme } from "styled-components";
import { Components } from "@thisgorilla/primate-ds";
import { Otp as DSOtp } from "@thisgorilla/primate-ds/dist/inputs/Otp/Otp";

import { useAppSelector } from "../../../../redux";
import { AuthCommonStyle } from "../../style";
import { API_CALLS } from "../../../../API/api_calls";

const Otp = () => {
  const { resetFormValue } = useAppSelector((state) => state.auth);
  const { Color, typography } = useTheme();

  return (
    <>
      <center>
        <AuthCommonStyle.Container>
          <Components.Text
            style={{
              tokenStyle: typography.Heading.L,
            }}
            text="Check your email for a verification code"
            fontWeight="font-weight-semibold"
          />
          <div>
            <Components.Text
              style={{
                tokenStyle: typography.Body.M,
              }}
              text="An email has been sent to "
              fontWeight="font-weight-semibold"
              color={Color.text["text-secondary"].value}
            />
            <Components.Text
              style={{
                tokenStyle: typography.Body.M,
              }}
              text={resetFormValue.email}
              color={Color.text["text-secondary"].value}
              fontWeight="font-weight-semibold"
              isunderlined
            />
            <Components.Text
              style={{
                tokenStyle: typography.Body.M,
              }}
              fontWeight="font-weight-semibold"
              text=". Please check your email
          and enter the One-Time Pin"
              color={Color.text["text-secondary"].value}
            />
          </div>
        </AuthCommonStyle.Container>
      </center>
      <center>
        <AuthCommonStyle.GroupContainer>
          <AuthCommonStyle.Container>
            <div style={{ margin: "auto" }}>
              <DSOtp
                idPrefix="otp"
                otpChangeHandler={(otp) => {
                  if (otp.length < 4) return;
                  API_CALLS.AUTH.checkOtp({ otp, email: resetFormValue.email });
                }}
              />
            </div>
            <Components.Text
              style={{
                tokenStyle: typography.Body.S,
              }}
              text="Can’t find your code? Check your spam folder!"
              color={Color.text["text-secondary"].value}
              fontWeight="font-weight-semibold"
            />
          </AuthCommonStyle.Container>
          <div onClick={() => API_CALLS.AUTH.resendOtp(resetFormValue.email)}>
            <Components.Text
              style={{
                tokenStyle: typography.Body.S,
              }}
              text="or "
              color={Color.text["text-secondary"].value}
              fontWeight="font-weight-semibold"
            />
            <Components.Text
              style={{
                tokenStyle: typography.Body.S,
              }}
              text="Send New Code"
              color={Color.text["text-emphasize-brand"].value}
              fontWeight="font-weight-semibold"
              isunderlined
            />
          </div>
        </AuthCommonStyle.GroupContainer>
      </center>
    </>
  );
};

export default Otp;
