import { SetStateAction, Dispatch } from "react";

import { handleError } from "../errors";
import { disableScroll } from "../../utils";
import store, { actions } from "../../redux";
import { API_INSTANCES } from "../api_instance";
import { PostsProps } from "../../redux/slices/posts/Posts";

const dispatch = store.dispatch;

const createPostDraft = (
  setCreatePostOpen: Dispatch<SetStateAction<boolean>>
) => {
  return API_INSTANCES.POSTS.createPostDraft()
    .then((response) => {
      const data = response.data as PostsProps.createPostDraftResponse;
      dispatch(actions.posts.setCreatePostDraftResponse(data));
      setCreatePostOpen(true);
      disableScroll();
    })
    .catch((error) => handleError(error, createPostDraft.name));
};

const activateAPost = (
  data: PostsProps.activateAPostPayload,
  setCreatePostOpen: Dispatch<SetStateAction<boolean>>
) => {
  return API_INSTANCES.POSTS.activateAPost(data)
    .then((response) => {
      const data = response.data as PostsProps.activateAPostResponse;
      dispatch(actions.posts.setActivateAPostResponse(data));
      setCreatePostOpen(false);
    })
    .catch((error) => handleError(error, activateAPost.name));
};

const repostAPost = (data: PostsProps.repostAPostPayload) =>
  API_INSTANCES.POSTS.repostAPost(data)
    .then()
    .catch((error) => handleError(error, repostAPost.name));

const getSinglePost = (data: PostsProps.getSinglePostPayload) =>
  API_INSTANCES.POSTS.getSinglePost(data)
    .then()
    .catch((error) => handleError(error, getSinglePost.name));

const uploadsImageForAPost = (data: PostsProps.uploadsImageForAPostPayload) =>
  API_INSTANCES.POSTS.uploadsImageForAPost(data)
    .then()
    .catch((error) => handleError(error, uploadsImageForAPost.name));

const deleteAnImageForAPost = (data: PostsProps.deleteAnImageForAPostPayload) =>
  API_INSTANCES.POSTS.deleteAnImageForAPost(data)
    .then()
    .catch((error) => handleError(error, deleteAnImageForAPost.name));

const getUserPosts = (data: PostsProps.getUserPostsPayload) =>
  API_INSTANCES.POSTS.getUserPosts(data)
    .then()
    .catch((error) => handleError(error, getUserPosts.name));

export const POSTS = {
  createPostDraft,
  activateAPost,
  repostAPost,
  getSinglePost,
  uploadsImageForAPost,
  deleteAnImageForAPost,
  getUserPosts,
};
