import { Field, FieldProps } from "formik";
import styled, { useTheme } from "styled-components";
import { Components, Inputs } from "@thisgorilla/primate-ds";

import { durationOption } from "../../utils";

const Duration = () => {
  const { typography } = useTheme();
  const fieldName = "duration";

  return (
    <Field name={fieldName} key={fieldName}>
      {({ field, meta, form }: FieldProps) => {
        const { value } = field;
        const { error } = meta;
        const { setFieldValue, validateField } = form;
        return (
          <DurationContainer>
            <DurationHeading>
              <Components.Text
                style={{ tokenStyle: typography.Heading.XXS }}
                text="Poll duration"
                fontWeight="font-weight-semibold"
                lineHeight={"line-height-50"}
              />
              <Components.Tooltip
                label="Select the duration for your poll. After this time, voting will close automatically."
                width={240}
                parentElementId="create-poll"
              >
                <Components.IconButton
                  iconType="Info"
                  disabled={false}
                  kind="Secondary"
                  onClick={() => {}}
                  size="XS"
                />
              </Components.Tooltip>
            </DurationHeading>
            <div>
              {durationOption.map((durationOption, index) => (
                <Components.ListItem
                  key={index}
                  type="List Item"
                  label={durationOption.label}
                  selected={value === durationOption.value}
                  onClick={() => {
                    setFieldValue(fieldName, durationOption.value).then(() => {
                      validateField(fieldName);
                    });
                  }}
                  radio
                />
              ))}
              {error && (
                <Inputs.CommonField.HelpText content={error} kind="Error" />
              )}
            </div>
          </DurationContainer>
        );
      }}
    </Field>
  );
};

export default Duration;

const DurationContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: ${({ theme }) => theme.spacing["spacing-2"].value}px;
`;
const DurationHeading = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: ${({ theme }) => theme.spacing["spacing-2"].value}px;
`;
