import { KeyboardEvent, useEffect, useState } from "react";
import { useTheme } from "styled-components";
import { Formik, FormikValues } from "formik";
import { useNavigate } from "react-router-dom";
import { Components } from "@thisgorilla/primate-ds";

import Input from "../../../Input";
import { AuthCommonStyle } from "../../style";
import { resetValidationSchema } from "../utils";
import { actions, useAppDispatch, useAppSelector } from "../../../../redux";
import { API_CALLS } from "../../../../API/api_calls";

const Reset = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { Color, typography } = useTheme();
  const [submitClicked, setSubmitClicked] = useState(false);
  const [isPasswordShow, setIsPasswordShow] = useState(false);
  const [isConfirmPasswordShow, setIsConfirmPasswordShow] = useState(false);
  const { resetPasswordAPI_STATUS, resetFormValue } = useAppSelector(
    (state) => state.auth
  );

  useEffect(() => {
    dispatch(actions.auth.setForgotPasswordAPI_STATUS("idle"));
  }, [dispatch]);

  const onSubmit = (values: FormikValues) => {
    API_CALLS.AUTH.resetPassword(
      {
        email: resetFormValue.email,
        otp: resetFormValue.otp,
        new_password: values.password,
      },
      navigate
    );
  };

  return (
    <AuthCommonStyle.Wrapper>
      <AuthCommonStyle.GroupContainer>
        <center>
          <Components.Text
            style={{
              tokenStyle: typography.Heading.L,
            }}
            text="Reset your password"
            fontWeight="font-weight-semibold"
          />
        </center>
        <center>
          <Components.Text
            style={{
              tokenStyle: typography.Body.M,
            }}
            text="Please create a new password to log in"
            color={Color.text["text-emphasize-brand-bolder"].value}
            fontWeight="font-weight-semibold"
          />
        </center>
      </AuthCommonStyle.GroupContainer>

      <Formik
        initialValues={{ password: "", confirm_password: "" }}
        onSubmit={onSubmit}
        validationSchema={resetValidationSchema}
      >
        {(formik) => {
          const onKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
            const isEnterKey = e.key === "Enter";
            if (isEnterKey) formik.submitForm();
          };
          return (
            <AuthCommonStyle.Container>
              <Input
                fieldName="password"
                placeholder="New Password"
                idPrefix="reset"
                isRequired
                showPasswordStrength
                type={isPasswordShow ? "text" : "password"}
                iconType={isPasswordShow ? "EyeOff" : "Eye"}
                iconClickhandler={() => setIsPasswordShow(!isPasswordShow)}
                onKeyDown={onKeyDown}
              />
              <Input
                placeholder="Confirm Password"
                idPrefix="reset"
                isRequired
                fieldName="confirm_password"
                type={isConfirmPasswordShow ? "text" : "password"}
                iconType={isConfirmPasswordShow ? "EyeOff" : "Eye"}
                iconClickhandler={() =>
                  setIsConfirmPasswordShow(!isConfirmPasswordShow)
                }
                onKeyDown={onKeyDown}
              />
              <Components.Button
                color="Primary"
                kind="Primary"
                label="Reset password"
                size="Large"
                width="fill"
                onClick={() => {
                  setSubmitClicked(true);
                  formik.isValid && formik.submitForm();
                }}
                disabled={
                  (submitClicked && !formik.isValid) ||
                  resetPasswordAPI_STATUS === "progress" ||
                  Object.keys(formik.errors).length > 0
                }
              />
            </AuthCommonStyle.Container>
          );
        }}
      </Formik>
    </AuthCommonStyle.Wrapper>
  );
};

export default Reset;
