import { Formik, FormikValues } from "formik";
import { createContext, useState } from "react";

import ModalHeader from "./ModalHeader";
import ModalFooter from "./ModalFooter";
import ModalContent from "./ModalContent";
import { Modal } from "../../../../Common";
import { useAppSelector } from "../../../../../redux";
import { Divider } from "../../../../../commonStyles";
import { CreatePostModalProps } from "./CreatePostModal";
import { API_CALLS } from "../../../../../API/api_calls";
import { CHOICE_COUNT_INITIAL_VALUE, createPollValidation } from "./utils";

export const CreatePostModalContext = createContext(
  {} as CreatePostModalProps.Context
);

const CreatePostModal = ({
  setCreatePostOpen,
}: CreatePostModalProps.IProps) => {
  const [pollOpen, setPollOpen] = useState(false);
  const [imageUploadOpen, setImageUploadOpen] = useState(false);
  const [choiceCount, setChoiceCount] = useState(CHOICE_COUNT_INITIAL_VALUE);
  const [urlData, setUrlData] = useState<CreatePostModalProps.CustomURL | null>(
    null
  );

  const { post_id } = useAppSelector((state) => state.posts);

  const postSubmit = (values: FormikValues) => {
    const newValues = { ...values };
    delete newValues.duration;
    delete newValues.content;
    delete newValues.editorState;
    delete newValues.question;
    const newChoice: FormikValues = {};

    Object.values(newValues).forEach((choice, index) => {
      newChoice[`choice_${index + 1}`] = choice;
    });

    API_CALLS.POSTS.activateAPost(
      {
        content: values.content || null,
        delivery_date: new Date().toISOString(),
        node_tree: JSON.stringify(values.editorState?.state?.toJSON()),
        post_id,
        topics: [],
        url_id: urlData?.id ?? null,
        poll: pollOpen
          ? {
              ...newChoice,
              post_id,
              question: values.question,
              duration: values.duration,
            }
          : null,
      },
      setCreatePostOpen
    );
  };

  return (
    <Formik
      initialValues={{}}
      validationSchema={createPollValidation(choiceCount, pollOpen)}
      onSubmit={postSubmit}
      validateOnChange={false}
      validateOnBlur
    >
      {(formik) => {
        const contextValue: CreatePostModalProps.Context = {
          setCreatePostOpen,
          urlData,
          setUrlData,
          pollOpen,
          setPollOpen,
          choiceCount,
          setChoiceCount,
          formik,
          setImageUploadOpen,
          imageUploadOpen,
        };

        return (
          <CreatePostModalContext.Provider value={contextValue}>
            <Modal>
              <ModalHeader />
              <ModalContent />
              <Divider />
              <ModalFooter />
            </Modal>
          </CreatePostModalContext.Provider>
        );
      }}
    </Formik>
  );
};

export default CreatePostModal;
