import { Fragment, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { ROUTES } from "../utills/routes";
import { useAppSelector } from "../redux";
import { Layout } from "./Layout";
import Loading from "./Loading";
import { API_CALLS } from "../API/api_calls";

export const Protected = ({ component }: { component: JSX.Element }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { isAuthenticated, getStatusAPI_STATUS } = useAppSelector(
    (state) => state.auth
  );

  useEffect(() => {
    if (getStatusAPI_STATUS !== "idle") return;
    API_CALLS.AUTH.getStatus();
  }, [getStatusAPI_STATUS]);

  useEffect(() => {
    if (isAuthenticated) return;
    if (getStatusAPI_STATUS === "idle") return;
    if (getStatusAPI_STATUS === "progress") return;

    console.log("REDIRECTION HAPPENING");
    navigate(`${ROUTES.login}?redirectURL=${location.pathname}`);
  }, [isAuthenticated, getStatusAPI_STATUS, location.pathname, navigate]);

  if (getStatusAPI_STATUS === "progress" || getStatusAPI_STATUS === "idle")
    return <Loading />;
  if (isAuthenticated) return <Layout>{component}</Layout>;
  return <Fragment />;
};
