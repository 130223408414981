import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { useAppSelector } from "../../../redux";
import { useRedirectUrl } from "./useRedirectUrl";

export const useAuthRedirect = () => {
  const { isAuthenticated } = useAppSelector((state) => state.auth);
  const navigate = useNavigate();

  const redirectURL = useRedirectUrl();

  useEffect(() => {
    if (!isAuthenticated) return;
    navigate(redirectURL);
  }, [isAuthenticated, redirectURL, navigate]);
};
