import styled from "styled-components";

const Container = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing["spacing-2"].value}px;
  align-items: center;
  cursor: pointer;
  border-radius: ${({ theme }) => theme.radius["radius-xs"].value}px;
  padding: ${({ theme }) => theme.spacing["spacing-3"].value}px
    ${({ theme }) => theme.spacing["spacing-2"].value}px;
  &:hover {
    background: ${({ theme }) =>
      theme.Color.elevation.surface["surface-primary-hover"].value};
  }
`;
const JobInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: ${({ theme }) => theme.spacing["spacing-1"].value}px;

  & > label:last-child {
    white-space: nowrap;
  }
`;

export const JobCardStyles = { Container, JobInfoContainer, TitleContainer };
