import styled from "styled-components";

import { LeftPanelCommonStyles } from "../Common";
import { getHexValueForPercentage } from "../../../../../utils";
import { TitleAndButton } from "./TitleAndButton";
import { ExistingTroops } from "./ExistingTroops";
import { RecommendedTroops } from "./RecommendedTroops";

export const Troops = () => {
  return (
    <Container>
      <TitleAndButton />
      <LeftPanelCommonStyles.Underline />
      <ExistingTroops />
      <LeftPanelCommonStyles.Underline />
      <RecommendedTroops />
    </Container>
  );
};

const Container = styled.div`
  ${LeftPanelCommonStyles.getGradientBackgroundComponent(
    ` #09D195${getHexValueForPercentage(20)}, #FFFFFF 60%`
  )};
`;
