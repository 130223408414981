import { createSlice } from "@reduxjs/toolkit";

import { PostsProps } from "./Posts";

const intailActivateAPostResponse: PostsProps.activateAPostResponse = {
  ID: "",
  UserID: "",
  EmbedPostID: "",
  DeliveryDate: "",
  CreatedAt: "",
  UpdatedAt: "",
  UserDetails: {
    name: "",
    profilePicture: "",
    info: "",
    activeDuration: "",
  },
  topics: [],
  PostMeta: {
    post_urls: [],
    post_users: [],
  },
  Content: {
    ID: "",
    PostID: "",
    Blueprint: "",
    NodeTree: "",
    PlainText: "",
    Summary: "",
    CleanedText: "",
    TokenizedText: "",
    CreatedAt: "",
    UpdatedAt: "",
  },
  Media: {
    Images: [],
  },
  interactions: {
    poll: {},
    slider: {},
  },
  Likes: 0,
  Dislikes: 0,
  LikePercentage: 0,
  DislikePercentage: 0,
  MainCommentCount: 0,
  ReactionCounts: {},
  TotalCuratedReactions: 0,
  OriginalPost: null,
};

const initialState = {
  post_id: "",
  activateAPostResponse: intailActivateAPostResponse,
} as PostsProps.State;

const slice = createSlice({
  name: "posts",
  initialState,
  reducers: {
    setCreatePostDraftResponse: (
      state,
      action: PostsProps.setCreatePostDraftResponse
    ) => {
      state.post_id = action.payload.id;
    },
    setActivateAPostResponse: (
      state,
      action: PostsProps.setActivateAPostResponse
    ) => {
      state.activateAPostResponse = action.payload;
    },
    reset: () => initialState,
  },
});

export const postsActions = slice.actions;
export const postsReducer = slice.reducer;
