import { Components } from "@thisgorilla/primate-ds";
import styled, { useTheme } from "styled-components";

type CounterProps = { title: string; count: number | string };
export const Counter = ({ title, count }: CounterProps) => {
  const { typography } = useTheme();
  return (
    <Container>
      <Components.Text
        text={title}
        style={{ tokenStyle: typography.Heading.XXS }}
        fontWeight="font-weight-semibold"
      />
      <Components.Counter color="Dark" kind="Fill" count={count} size="Small" />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 32px;
  box-sizing: border-box;
  gap: ${({ theme }) => theme.spacing["spacing-2"].value}px;
`;
