import { useContext } from "react";
import styled, { useTheme } from "styled-components";
import { Components } from "@thisgorilla/primate-ds";

import { CreatePostModalContext } from "..";
import { CreatePostModalProps } from "../CreatePostModal";

const UrlPreview = ({ setIsPreviewShow }: CreatePostModalProps.UrlPreview) => {
  const { typography, Color } = useTheme();
  const { urlData } = useContext(CreatePostModalContext);

  return (
    <Container>
      <PreviewContainer>
        <Image src={urlData?.imageUrl} />

        <PreviewCloseContainer>
          <Components.IconButton
            disabled={false}
            iconType="X"
            kind="Tertiary"
            onClick={() => {
              setIsPreviewShow(false);
            }}
            size="Small"
          />
        </PreviewCloseContainer>
        <UrlInfoContainer>
          <Components.Text
            style={{ tokenStyle: typography.Body.L }}
            text={urlData?.url ?? ""}
            color={Color.text["text-tertiary"].value}
            maxLineLength={1}
          />
          <Components.Text
            style={{ tokenStyle: typography.Heading.S }}
            text={urlData?.title ?? ""}
            fontWeight="font-weight-semibold"
            maxLineLength={1}
          />
          <Components.Text
            style={{ tokenStyle: typography.Body.L }}
            text={urlData?.description ?? ""}
            color={Color.text["text-tertiary"].value}
            maxLineLength={1}
          />
        </UrlInfoContainer>
      </PreviewContainer>
    </Container>
  );
};

export default UrlPreview;

const Container = styled.div`
  padding: ${({ theme }) => theme.spacing["spacing-2"].value}px;
  border-radius: ${({ theme }) => theme.radius["radius-xs"].value}px;
  border: 1px solid
    ${({ theme }) => theme.Color.border["ui-border-neutral"].value};
  background: ${({ theme }) =>
    theme.Color.elevation.surface["surface-secondary"].value};
  box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.1);
`;
const PreviewContainer = styled.div`
  position: relative;
  padding: ${({ theme }) => theme.spacing["spacing-2"].value}px;
  border-radius: ${({ theme }) => theme.radius["radius-xs"].value}px;
  background: ${({ theme }) =>
    theme.Color.elevation.surface["surface-primary-emphasize-hover"].value};
`;

const Image = styled.img`
  width: 100%;
  display: block;
  border-radius: ${({ theme }) => theme.radius["radius-xs"].value}px
    ${({ theme }) => theme.radius["radius-xs"].value}px
    ${({ theme }) => theme.radius["radius-none"].value}px
    ${({ theme }) => theme.radius["radius-none"].value}px;
`;

const PreviewCloseContainer = styled.div`
  position: absolute;
  top: ${({ theme }) => theme.spacing["spacing-4"].value}px;
  right: ${({ theme }) => theme.spacing["spacing-4"].value}px;
  border-radius: ${({ theme }) => theme.radius["radius-xxs"].value}px;
  background: ${({ theme }) =>
    theme.Color.elevation.surface["surface-primary"].value};
`;

const UrlInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing["spacing-2"].value}px;
  padding: 11px 18px;
  background: linear-gradient(
      0deg,
      ${({ theme }) => theme.Color.elevation.surface["surface-secondary"].value}
        0%,
      ${({ theme }) => theme.Color.elevation.surface["surface-secondary"].value}
        100%
    ),
    ${({ theme }) => theme.Color.elevation.surface["surface-primary"].value};
`;
