import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { PostsProps } from "./Posts";

const intailActivateAPostResponse: PostsProps.activateAPostResponse = {
  ID: "",
  UserID: "",
  EmbedPostID: "",
  DeliveryDate: "",
  CreatedAt: "",
  UpdatedAt: "",
  UserDetails: {
    name: "",
    profilePicture: "",
    info: "",
    activeDuration: "",
  },
  topics: [],
  PostMeta: {
    post_urls: [],
    post_users: [],
  },
  Content: {
    ID: "",
    PostID: "",
    Blueprint: "",
    NodeTree: "",
    PlainText: "",
    Summary: "",
    CleanedText: "",
    TokenizedText: "",
    CreatedAt: "",
    UpdatedAt: "",
  },
  Media: {
    Images: [],
  },
  interactions: {
    poll: {
      id: "",
      post_id: "",
      question: "",
      choice_1: "",
      choice_2: "",
      choice_3: "",
      choice_4: "",
      choice_5: "",
      choice_6: "",
      choice_7: "",
      choice_8: "",
      choice_9: "",
      choice_10: "",
      votes: {},
      leading_choice: "",
      total_votes: 0,
      created_at: "",
      duration: 0,
      active: true,
    },
    slider: {},
  },
  Likes: 0,
  Dislikes: 0,
  LikePercentage: 0,
  DislikePercentage: 0,
  MainCommentCount: 0,
  ReactionCounts: {},
  TotalCuratedReactions: 0,
  OriginalPost: null,
};

const initialState = {
  post_id: "",
  activateAPostResponse: intailActivateAPostResponse,
  uploadedImages: [],
  uploadedImagesUrls: [],
} as PostsProps.State;

const slice = createSlice({
  name: "posts",
  initialState,
  reducers: {
    setCreatePostDraftResponse: (
      state,
      action: PostsProps.setCreatePostDraftResponse
    ) => {
      state.post_id = action.payload.id;
    },
    setActivateAPostResponse: (
      state,
      action: PostsProps.setActivateAPostResponse
    ) => {
      state.activateAPostResponse = action.payload;
    },
    setUploadsImageForAPostResponse: (
      state,
      action: PostsProps.setUploadsImageForAPostResponse
    ) => {
      state.uploadedImages = action.payload;
    },
    setUploadedImagesUrls: (
      state,
      action: PayloadAction<string>
    ) => {
      state.uploadedImagesUrls = [...state.uploadedImagesUrls, action.payload];
    },
    setDeleteImageUrl: (state, action: PayloadAction<number>) => {
      state.uploadedImagesUrls = state.uploadedImagesUrls.filter(
        (_, index) => index !== action.payload
      );
    },
    reset: () => initialState,
  },
});

export const postsActions = slice.actions;
export const postsReducer = slice.reducer;
