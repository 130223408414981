import { useLocation } from "react-router-dom";

import { ROUTES } from "../../../utills/routes";

export const useRedirectUrl = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const redirectURL = searchParams.get("redirectURL") || ROUTES.home;
  return redirectURL;
};
