import { Components, Inputs } from "@thisgorilla/primate-ds";

import styled from "styled-components";

export const AvatarAndText = () => {
  const idPrefix = "default-post";

  return (
    <Container>
      <Components.Avatar
        type="Circle"
        size="XSmall-28"
        src="images/common/Gorilla.png"
        disabled={false}
      />
      <div style={{ width: "100%" }}>
        <Inputs.Input
          idPrefix={idPrefix}
          fieldName="post"
          isRequired={false}
          onChangeHandler={() => {}}
          value=""
          placeholder="Start posting"
          size="Small"
        />
      </div>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
`;
