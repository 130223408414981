import { useEffect, useState } from "react";

export const useMouseOver = ({ id }: { id: string }) => {
  const [isHovered, setIsHovered] = useState(false);

  useEffect(() => {
    const element = document.getElementById(id);
    if (!element) return;

    const handleMouseEnter = () => setIsHovered(true);
    const handleMouseLeave = () => setIsHovered(false);

    element.addEventListener("mouseenter", handleMouseEnter);
    element.addEventListener("mouseleave", handleMouseLeave);

    return () => {
      element.removeEventListener("mouseenter", handleMouseEnter);
      element.removeEventListener("mouseleave", handleMouseLeave);
    };
  }, [id]);

  return { isHovered };
};
