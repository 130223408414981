import { ReactNode } from "react";
import styled from "styled-components";

const Modal = ({ children }: { children: ReactNode }) => {
  return (
    <ModalOverlay>
      <ModalContainer>{children}</ModalContainer>
    </ModalOverlay>
  );
};

export default Modal;

const ModalOverlay = styled.div`
  position: fixed;
  inset: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ theme }) =>
    theme.Color.elevation.surface["surface-primary-hover"].value};
`;
const ModalContainer = styled.div`
  max-width: 726px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing["spacing-4"].value}px;
  padding: ${({ theme }) => theme.spacing["spacing-4"].value}px;
  border-radius: ${({ theme }) => theme.radius["radius-xs"].value}px;
  border: 1px solid
    ${({ theme }) => theme.Color.background.inverse.default.value};
  background: ${({ theme }) =>
    theme.Color.elevation.surface["surface-primary"].value};
`;
