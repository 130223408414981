import store, { actions } from "../../redux";
import { MeProps } from "../../redux/slices/me/Me";
import { API_INSTANCES } from "../api_instance";
import { handleError } from "../errors";

const dispatch = store.dispatch;

const getAllUsers = () => {
  API_INSTANCES.ME.getAllUsers()
    .then()
    .catch((error) => handleError(error, getAllUsers.name));
};
const getMentions = () => {
  API_INSTANCES.ME.getMentions()
    .then()
    .catch((error) => handleError(error, getMentions.name));
};
const getPeopleYouMightKnow = () => {
  dispatch(actions.me.setPeopleYouMightKnow_API_STATUS("progress"));
  API_INSTANCES.ME.getPeopleYouMightKnow()
    .then((response) => {
      const data = response.data as MeProps.PeopleYouMightKnow;
      dispatch(actions.me.setPeopleYouMightKnow(data));
      dispatch(actions.me.setPeopleYouMightKnow_API_STATUS("success"));
    })
    .catch((error) => handleError(error, getPeopleYouMightKnow.name));
};
const getRecommendedJobs = () => {
  API_INSTANCES.ME.getRecommendedJobs()
    .then((response) => {
      const data = response.data as MeProps.RecommendedJobs;
      dispatch(actions.me.setRecommendedJobs(data));
    })
    .catch((error) => handleError(error, getRecommendedJobs.name));
};
const getSuggestedUsers = () => {
  API_INSTANCES.ME.getSuggestedUsers()
    .then((response) => {
      const data = response.data as MeProps.SuggestedUsers;
      dispatch(actions.me.setSuggestedUsers(data));
    })
    .catch((error) => handleError(error, getSuggestedUsers.name));
};
const getUserInformation = () => {
  API_INSTANCES.ME.getUserInformation()
    .then((response) => {
      const data = response.data as MeProps.User;
      dispatch(actions.me.setUser(data));
    })
    .catch((error) => handleError(error, getUserInformation.name));
};
const getYourInnerCircle = () => {
  dispatch(actions.me.setYourInnerCircleAPI_STATUS("progress"));

  API_INSTANCES.ME.getYourInnerCircle()
    .then((response) => {
      const data = response.data as MeProps.YourInnerCircle;
      dispatch(actions.me.setYourInnerCircle(data));
      dispatch(actions.me.setYourInnerCircleAPI_STATUS("success"));
    })
    .catch((error) => handleError(error, getYourInnerCircle.name));
};

export const ME = {
  getAllUsers,
  getMentions,
  getPeopleYouMightKnow,
  getRecommendedJobs,
  getSuggestedUsers,
  getUserInformation,
  getYourInnerCircle,
};
