import styled, { css } from "styled-components";

export const hideScrollBar = css`
  scrollbar-width: none;
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    display: none;
  }
`;

export const Divider = styled.div<{ $vertical?: boolean; $color?: string }>`
  width: ${({ $vertical }) => ($vertical ? "1px" : "100%")};
  height: ${({ $vertical }) => ($vertical ? "auto" : "1px")};
  background: ${({ theme, $color }) =>
    $color ? $color : theme.Color.border["ui-border-neutral"].value};
`;
