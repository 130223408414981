import { useRef, useState } from "react";
import styled from "styled-components";

import MediaUpload from "./MediaUpload";
import { validFile, validFileTypes } from "./utils";
import MultiImagePreview from "./MultiImagePreview";
import SingleImagePreview from "./SingleImagePreview";
import { useAppSelector } from "../../../../../../../redux";
import { API_CALLS } from "../../../../../../../API/api_calls";

const ImageUpload = () => {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [fileUploadShow, setFileUploadShow] = useState(true);
  const { uploadedImages, post_id } = useAppSelector(
    (state) => state.posts
  );
  const isMulti = uploadedImages.length > 1;

  const uploadFile = (files: FileList) => {
    const validFileList = Array.from(files).filter((file) => validFile(file));
    if (!validFileList.length) return;

    validFileList.forEach((file) => {
      API_CALLS.POSTS.uploadsImageForAPost(
        {
          post_id,
          image: file,
        },
        setFileUploadShow
      );
    });
  };

  return (
    <Container $imgpreview={!fileUploadShow} $multi={isMulti}>
      {fileUploadShow ? (
        <MediaUpload uploadFile={uploadFile} fileInputRef={fileInputRef} />
      ) : isMulti ? (
        <MultiImagePreview />
      ) : (
        <SingleImagePreview fileInputRef={fileInputRef} setFileUploadShow={setFileUploadShow} />
      )}
      <HiddenInput
        type="file"
        multiple
        onChange={(e) => {
          const files = e.target.files;
          if (files?.length) {
            uploadFile(files);
          }
        }}
        ref={fileInputRef}
        id="image-upload"
        accept={validFileTypes.join(",")}
      />
    </Container>
  );
};

export default ImageUpload;

const Container = styled.div<{ $imgpreview: boolean; $multi: boolean }>`
  padding: ${({ theme }) => theme.spacing["spacing-2"].value}px;
  border-radius: ${({ theme, $imgpreview }) =>
    theme.radius[$imgpreview ? "radius-m" : "radius-xs"].value}px;
  box-shadow: ${({ theme }) => theme.boxShadows["box-shadow-s"]};
  border: ${({ theme, $multi }) =>
    $multi
      ? "none"
      : "1px solid " + theme.Color.border["ui-border-neutral"].value};
  background: ${({ theme }) =>
    theme.Color.elevation.surface["surface-secondary"].value};
`;

const HiddenInput = styled.input`
  display: none;
`;
