import { useEffect } from "react";
import { Components } from "@thisgorilla/primate-ds";
import { ListItemProps } from "@thisgorilla/primate-ds/dist/components/ListItem1";
import styled, { useTheme } from "styled-components";

import { useAppSelector } from "../../../../../redux";
import { API_CALLS } from "../../../../../API/api_calls";

export const SavedItems = () => {
  const { typography, Color } = useTheme();
  const { getSavedItemsCountsAPI_STATUS, jobs, posts, articles } =
    useAppSelector((state) => state.savedItems);

  useEffect(() => {
    if (getSavedItemsCountsAPI_STATUS !== "idle") return;
    API_CALLS.SAVED_ITEMS.getSavedItemsCounts();
  }, [getSavedItemsCountsAPI_STATUS]);

  const commonProps: ListItemProps.DefaultListItemProps = {
    type: "List Item",
    countColor: "Light",
    showSelected: false,
    label: "",
    onClick: () => {},
  };

  return (
    <Container>
      <Components.Text
        text={"Saved Items"}
        style={{ tokenStyle: typography.Heading.S }}
        fontWeight="font-weight-semibold"
        color={Color.background.warning["default-hover"].value}
      />
      <Items>
        {(
          [
            {
              ...commonProps,
              count: jobs,
              label: "Jobs",
              iconType: "Briefcase",
            },
            {
              ...commonProps,
              count: posts,
              label: "Posts",
              iconType: "Layout",
            },
            {
              ...commonProps,
              count: articles,
              label: "Articles",
              iconType: "FileText",
            },
          ] as ListItemProps.DefaultListItemProps[]
        ).map((listItem, i) => (
          <Components.ListItem {...listItem} key={i} />
        ))}
      </Items>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing["spacing-4"].value}px;
`;
const Items = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing["spacing-2"].value}px;
`;
