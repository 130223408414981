import styled from "styled-components";

import { HomeStyles } from "../home.styled";
import { LeftPanelCommonStyles } from "./Common";

import { UserDetails } from "./1-UserDetails";
import { FollowersFollowing } from "./2-FollowersFollowing";
import { PeopleYouMightKnow } from "./3-PeopleYouMightKnow";
import { SavedItems } from "./4-SavedItems";
import { InnerCircle } from "./5-InnerCircle";
import { Troops } from "./6-Troops";

export const LeftPanel = () => {
  return (
    <Container className="left-panel">
      <UserDetails />
      <LeftPanelCommonStyles.Underline />
      <FollowersFollowing />
      <LeftPanelCommonStyles.Underline />
      <PeopleYouMightKnow />
      <LeftPanelCommonStyles.Underline />
      <SavedItems />
      <LeftPanelCommonStyles.Underline />
      <InnerCircle />
      <LeftPanelCommonStyles.Underline />
      <Troops />
    </Container>
  );
};

const Container = styled.div`
  ${HomeStyles.defaultPanelContainer}
`;
