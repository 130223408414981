import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../../utills/routes";
import { Inputs } from "@thisgorilla/primate-ds";
import instance from "../../../axios/axios-user";
import { API_CALLS } from "../../../API/api_calls";

const Dashboard = () => {
  const navigate = useNavigate();
  return (
    <div>
      <button onClick={() => navigate(ROUTES.home)}>Home</button>
      <button onClick={() => navigate(ROUTES.dashboard)}>Dashboard</button>
      <button onClick={() => API_CALLS.AUTH.logout(navigate)}>Logout</button>

      <Inputs.RichTextarea
        axiosInstance={instance}
        fieldName="richtext"
        idPrefix="richtext"
        isRequired
        mentionList={[]}
        value=""
        onChangeHandler={(_value) => {}}
      />
    </div>
  );
};

export default Dashboard;
