import instance from "../../axios/axios-user";
import { API_PATHS } from "../api_paths";

const createSavedItem = () =>
  instance(API_PATHS.savedItems.createSavedItem, { method: "POST" });
const deleteSavedItem = () =>
  instance(API_PATHS.savedItems.deleteSavedItem, { method: "DELETE" });
const getSavedItemsCounts = () =>
  instance(API_PATHS.savedItems.getSavedItemsCounts);

export const SAVED_ITEMS = {
  createSavedItem,
  deleteSavedItem,
  getSavedItemsCounts,
};
