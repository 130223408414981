import { useContext } from "react";
import styled from "styled-components";
import { Components } from "@thisgorilla/primate-ds";

import Choices from "./Choices";
import Duration from "./Duration";
import { CreatePostModalContext } from "../..";
import { CHOICE_COUNT_INITIAL_VALUE } from "../../utils";
import { Divider } from "../../../../../../../commonStyles";
import Input from "../../../../../../Input";

const CreatePoll = () => {
  const { choiceCount, setPollOpen, formik, setChoiceCount } = useContext(
    CreatePostModalContext
  );

  return (
    <PollContainer id="create-poll">
      <HeaderContainer>
        <Input
          fieldName="question"
          idPrefix="create-poll"
          isRequired
          placeholder="Question"
          size="Small"
        />
        <Components.IconButton
          disabled={false}
          iconType="X"
          kind="Secondary"
          size="Small"
          onClick={() => {
            setPollOpen(false);
            formik.resetForm();
            setChoiceCount(CHOICE_COUNT_INITIAL_VALUE);
          }}
        />
      </HeaderContainer>
      <Divider />
      <ChoicesAndDurationContainer $column={choiceCount.length > 5}>
        <Choices />
        <Divider $vertical={choiceCount.length < 6} />
        <Duration />
      </ChoicesAndDurationContainer>
      <Divider />
    </PollContainer>
  );
};

export default CreatePoll;

const PollContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing["spacing-2"].value}px;
  padding: ${({ theme }) => theme.spacing["spacing-2"].value}px
    ${({ theme }) => theme.spacing["spacing-2"].value}px
    ${({ theme }) => theme.spacing["spacing-6"].value}px;
  border-radius: ${({ theme }) => theme.radius["radius-xs"].value}px;
  border: 1px solid
    ${({ theme }) => theme.Color.border["ui-border-neutral"].value};
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: ${({ theme }) => theme.spacing["spacing-6"].value}px;
`;

const ChoicesAndDurationContainer = styled.div<{ $column: boolean }>`
  display: flex;
  flex-direction: ${({ $column }) => ($column ? "column" : "row")};
  gap: ${({ theme }) => theme.spacing["spacing-2"].value}px;
`;
