import styled from "styled-components";
import { Components } from "@thisgorilla/primate-ds";

export const Attachments = () => {
  return (
    <Container className="default-post-attachments">
      <Components.Tooltip label="Add media">
        <Components.IconButton
          iconType={"Image"}
          kind="Secondary"
          size="Medium"
          onClick={() => {}}
          disabled={false}
        />
      </Components.Tooltip>

      <Components.Tooltip label="Add a GIF">
        <Components.IconButton
          iconType={"Gif"}
          kind="Secondary"
          size="Medium"
          onClick={() => {}}
          disabled={false}
        />
      </Components.Tooltip>

      <Components.Tooltip label="Create a poll">
        <Components.IconButton
          iconType={"Poll"}
          kind="Secondary"
          size="Medium"
          onClick={() => {}}
          disabled={false}
        />
      </Components.Tooltip>

      <Components.Tooltip label="Create a slider">
        <Components.IconButton
          iconType={"Slider"}
          kind="Secondary"
          size="Medium"
          onClick={() => {}}
          disabled={false}
        />
      </Components.Tooltip>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`;
