import { createSlice } from "@reduxjs/toolkit";
import { SavedItemsProps } from "./SavedItems";

const defaultSavedItemCounts: SavedItemsProps.SavedItemsCounts = {
  articles: 0,
  jobs: 0,
  posts: 0,
};
const defaultCreateSavedItemRequest: SavedItemsProps.CreateSavedItemRequest = {
  item_id: "",
  item_type: "",
};
const defaultCreateSavedItemResponse: SavedItemsProps.CreateSavedItemResponse =
  {
    createdAt: "",
    deletedAt: "",
    id: "",
    item_id: "",
    item_type: "",
    userID: "",
  };
const defaultDeleteSavedItemRequest: SavedItemsProps.DeleteSavedItemRequest = {
  id: "",
};
const defaultDeleteSavedItemResponse: SavedItemsProps.DeleteSavedItemResponse =
  {
    message: "",
  };
const defaultAPI: SavedItemsProps.API = {
  getSavedItemsCountsAPI_STATUS: "idle",
  createSavedItemAPI_STATUS: "idle",
  deleteSavedItemAPI_STATUS: "idle",
};

const initialState: SavedItemsProps.State = {
  ...defaultSavedItemCounts,
  ...defaultAPI,
  createSavedItemRequest: defaultCreateSavedItemRequest,
  createSavedItemResponse: defaultCreateSavedItemResponse,
  deleteSavedItemRequest: defaultDeleteSavedItemRequest,
  deleteSavedItemResponse: defaultDeleteSavedItemResponse,
};

const slice = createSlice({
  name: "saved items",
  initialState,
  reducers: {
    setSavedItemsCounts: (
      state,
      action: SavedItemsProps.setSavedItemsCounts
    ) => {
      state.articles = action.payload.articles;
      state.jobs = action.payload.jobs;
      state.posts = action.payload.posts;
    },
    setCreateSavedItemResponse: (
      state,
      action: SavedItemsProps.setCreateSavedItemResponse
    ) => {
      state.createSavedItemResponse = {
        ...defaultCreateSavedItemResponse,
        ...action.payload,
      };
    },

    setGetSavedItemsCountsAPI_STATUS: (
      state,
      action: SavedItemsProps.setGetSavedItemsCountsAPI_STATUS
    ) => {
      state.getSavedItemsCountsAPI_STATUS = action.payload;
    },
    setCreateSavedItemAPI_STATUS: (
      state,
      action: SavedItemsProps.setCreateSavedItemAPI_STATUS
    ) => {
      state.createSavedItemAPI_STATUS = action.payload;
    },
    setDeleteSavedItemAPI_STATUS: (
      state,
      action: SavedItemsProps.setDeleteSavedItemAPI_STATUS
    ) => {
      state.deleteSavedItemAPI_STATUS = action.payload;
    },
  },
});

export const savedItemsActions = slice.actions;
export const savedItemsReducer = slice.reducer;
