import { ReactNode, useEffect } from "react";

import Otp from "./component/Otp";
import Reset from "./component/Reset";
import Forgot from "./component/Forgot";
import AuthLayout from "../AuthLayout";
import { AuthProps } from "../../../redux/slices/auth/auth";
import { actions, useAppDispatch, useAppSelector } from "../../../redux";
import { useAuthRedirect } from "../hooks/useAuthRedirect";

const ForgotPassword = () => {
  const dispatch = useAppDispatch();
  const { forgotPasswordStep } = useAppSelector((state) => state.auth);

  useEffect(() => {
    dispatch(actions.auth.setForgotPasswordStep("forgot"));
  }, [dispatch]);
  useAuthRedirect();

  const forgotComponent: {
    [key in AuthProps.ForgotPasswordStep]: ReactNode;
  } = {
    forgot: <Forgot />,
    reset: <Reset />,
    otp: <Otp />,
  };
  return <AuthLayout>{forgotComponent[forgotPasswordStep]}</AuthLayout>;
};

export default ForgotPassword;
