import styled from "styled-components";

import { HomeStyles } from "../home.styled";

const Container = styled.div`
  ${HomeStyles.defaultPanelContainer};
`;
const Title = styled.div`
  padding: ${({ theme }) => theme.spacing["spacing-1"].value}px
    ${({ theme }) => theme.spacing["spacing-4"].value}px
    ${({ theme }) => theme.spacing["spacing-1"].value}px
    ${({ theme }) => theme.spacing["spacing-3"].value}px;
`;
const Divider = styled.div`
  height: 1px;
  width: 100%;
  background: ${({ theme }) => theme.Color.border["ui-border-subtle"].value};
`;

export const RightPanelStyles = {
  Container,
  Title,
  Divider,
};
