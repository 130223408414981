import styled from "styled-components";

import { Attachments } from "./Attachments";
import { PostAs } from "./PostAs";

export const PostAsAndAttachments = () => {
  return (
    <Container>
      <PostAs />
      <Attachments />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 4px;
`;
