import { useContext } from "react";
import { FormikValues } from "formik";
import styled, { useTheme } from "styled-components";
import { Components } from "@thisgorilla/primate-ds";

import Input from "../../../../../../Input";
import { CreatePostModalContext } from "../..";

const Choices = () => {
  const { typography } = useTheme();
  const { choiceCount, setChoiceCount, formik } = useContext(
    CreatePostModalContext
  );

  const deleteField = (fieldName: string) => {
    formik.setValues((prevValues: FormikValues) => {
      const newValues = { ...prevValues };
      delete newValues[fieldName];
      return newValues;
    });
    formik.setFieldError(fieldName, undefined);
  };
  return (
    <ChoicesContainer>
      <ChoicesHeading>
        <Components.Text
          style={{ tokenStyle: typography.Heading.XXS }}
          text="Poll Options"
          fontWeight="font-weight-semibold"
          lineHeight={"line-height-50"}
        />
        <Components.IconButton
          iconType="Plus"
          disabled={choiceCount.length === 10}
          kind="Secondary"
          onClick={() =>
            setChoiceCount([
              ...choiceCount,
              choiceCount[choiceCount.length - 1] + 1,
            ])
          }
          size="XS"
        />
      </ChoicesHeading>
      <Container>
        <ChoicesContainer>
          {choiceCount.slice(0, 5).map((choiceNumber) => (
            <Input
              key={choiceNumber}
              fieldName={`choice_${choiceNumber}`}
              idPrefix="choice"
              isRequired
              placeholder={`Option ${choiceNumber}`}
              iconClickhandler={() => {
                if (choiceCount.length > 3) {
                  deleteField(`choice_${choiceNumber}`);
                  setChoiceCount(
                    choiceCount.filter((count) => count !== choiceNumber)
                  );
                }
              }}
              iconType="X"
            />
          ))}
        </ChoicesContainer>
        {choiceCount.slice(5).length > 0 && (
          <ChoicesContainer>
            {choiceCount.slice(5).map((choiceNumber) => (
              <Input
                key={choiceNumber}
                fieldName={`choice_${choiceNumber}`}
                idPrefix="choice"
                isRequired
                placeholder={`Option ${choiceNumber}`}
                iconClickhandler={() => {
                  if (choiceCount.length > 3) {
                    deleteField(`choice_${choiceNumber}`);
                    setChoiceCount(
                      choiceCount.filter((count) => count !== choiceNumber)
                    );
                  }
                }}
                iconType="X"
              />
            ))}
          </ChoicesContainer>
        )}
      </Container>
    </ChoicesContainer>
  );
};

export default Choices;

const ChoicesContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: ${({ theme }) => theme.spacing["spacing-2"].value}px;
`;
const Container = styled.div`
  display: flex;
  width: 100%;
  gap: ${({ theme }) => theme.spacing["spacing-2"].value}px;
`;

const ChoicesHeading = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: ${({ theme }) => theme.spacing["spacing-2"].value}px;
`;
