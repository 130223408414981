import styled, { css } from "styled-components";

const getGradientBackgroundComponent = (colors: string) =>
  css(({ theme }) => {
    const gradient = `linear-gradient(45deg, ${colors})`;
    return `
    display:flex;
    flex-direction:column;
    gap:8px;

    box-shadow: ${theme.boxShadows["box-shadow-s"]};
    padding: ${theme.spacing["spacing-2"].value}px;
    border-radius: ${theme.spacing["spacing-1"].value}px;
    background: ${gradient};
    position: relative;
    z-index: 0;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: -1;
      border-radius: ${theme.spacing["spacing-1"].value}px;
      padding: 1px; 
      background: ${gradient};
      -webkit-mask: inset(0 0 0 1px);
      mask: inset(0 0 0 1px);
    }
  `;
  });
const TitleAndButton = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;
const Underline = styled.div`
  height: 1px;
  background-color: ${({ theme }) =>
    theme.Color.border["ui-border-subtle"].value};
`;

export const LeftPanelCommonStyles = {
  TitleAndButton,
  Underline,
  getGradientBackgroundComponent,
};
