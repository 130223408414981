import * as Yup from "yup";

export const forgotValidationSchema = Yup.object({
  email: Yup.string().required("Email is required").email("Invalid email"),
});

export const resetValidationSchema = Yup.object({
  password: Yup.string().required("Password is required"),
  confirm_password: Yup.string()
    .oneOf(
      [Yup.ref("password")],
      "Confirm password does not match the new password"
    )
    .required("Please confirm your password"),
});
