import instance from "../../axios/axios-user";
import { API_PATHS } from "../api_paths";

const followAUser = () =>
  instance(API_PATHS.connections.followAUser, { method: "POST" });
const getConnections = () => instance(API_PATHS.connections.getConnections);
const getFollowCounts = () => instance(API_PATHS.connections.getFollowCounts);
const getFollowingUsers = () =>
  instance(API_PATHS.connections.getFollowingUsers);
const unfollowAUser = () =>
  instance(API_PATHS.connections.unfollowAUser, { method: "POST" });

export const CONNECTIONS = {
  followAUser,
  getConnections,
  getFollowCounts,
  getFollowingUsers,
  unfollowAUser,
};
