import { useState } from "react";
import styled from "styled-components";

import ModalHeader from "./ModalHeader";
import ModalFooter from "./ModalFooter";
import ModalContent from "./ModalContent";
import { Modal } from "../../../../Common";
import { CreatePostModalProps } from "./CreatePostModal";

const CreatePostModal = ({
  setCreatePostOpen,
}: CreatePostModalProps.IProps) => {
  const [value, setValue] = useState<CreatePostModalProps.EditorValue>({
    state: null,
    html: "",
  });
  const [urlData, setUrlData] = useState<CreatePostModalProps.CustomURL | null>(
    null
  );

  return (
    <Modal>
      <ModalHeader setCreatePostOpen={setCreatePostOpen} />
      <ModalContent
        urlData={urlData}
        setUrlData={setUrlData}
        setValue={setValue}
        value={value}
      />
      <Divider />
      <ModalFooter
        urlData={urlData}
        value={value}
        setCreatePostOpen={setCreatePostOpen}
      />
    </Modal>
  );
};

export default CreatePostModal;

const Divider = styled.div`
  width: 100%;
  height: 1px;
  background: ${({ theme }) => theme.Color.border["ui-border-neutral"].value};
`;
