import { useState } from "react";
import { Components } from "@thisgorilla/primate-ds";

import { DefaultPost } from "./DefaultPost";
import CreatePostModal from "./CreatePostModal";
import { API_CALLS } from "../../../../API/api_calls";
import { CenterPanelStyles } from "./CenterPanel.styled";

export const CenterPanel = () => {
  const [createPostOpen, setCreatePostOpen] = useState(false);
  return (
    <CenterPanelStyles.Container>
      <DefaultPost />
      <Components.Button
        color="Primary"
        kind="Primary"
        disabled={false}
        label="Create Post"
        onClick={() => API_CALLS.POSTS.createPostDraft(setCreatePostOpen)}
        size="Medium"
      ></Components.Button>
      {createPostOpen && (
        <CreatePostModal setCreatePostOpen={setCreatePostOpen} />
      )}
    </CenterPanelStyles.Container>
  );
};
