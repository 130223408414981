import { createSlice } from "@reduxjs/toolkit";

import { ConnectionsProps } from "./connections";

const followCounts: ConnectionsProps.FollowCounts = {
  followers_count: 0,
  following_count: 0,
};
const defaultAPI_Status: ConnectionsProps.API = {
  follow_count_API_STATUS: "idle",
};

const initialState = { ...followCounts, ...defaultAPI_Status };

const slice = createSlice({
  name: "connections",
  initialState,
  reducers: {
    setFollowCounts: (state, action: ConnectionsProps.setFollowCounts) => {
      const { followers_count = 0, following_count = 0 } = action.payload;
      state.followers_count = followers_count;
      state.following_count = following_count;
    },

    reset: () => initialState,
  },
});

export const connectionsActions = slice.actions;
export const connectionsReducer = slice.reducer;
