import { KeyboardEvent, useEffect, useState } from "react";
import { useTheme } from "styled-components";
import { Formik, FormikValues } from "formik";
import { NavLink, useNavigate } from "react-router-dom";
import { Components } from "@thisgorilla/primate-ds";

import Input from "../../Input";
import { Icons } from "../../icons";
import AuthLayout from "../AuthLayout";
import { AuthCommonStyle } from "../style";
import { ROUTES } from "../../../utills/routes";
import { registerValidationSchema } from "./utils";
import { API_PATHS } from "../../../API/api_paths";
import { actions, useAppDispatch, useAppSelector } from "../../../redux";
import { API_CALLS } from "../../../API/api_calls";
import { AuthProps } from "../../../redux/slices/auth/auth";
import { useRedirectUrl } from "../hooks/useRedirectUrl";
import { useAuthRedirect } from "../hooks/useAuthRedirect";

const Register = () => {
  const { registerAPI_STATUS, isAuthenticated } = useAppSelector(
    (state) => state.auth
  );
  const dispatch = useAppDispatch();

  const navigate = useNavigate();
  const { Color, typography } = useTheme();

  const [isSubmitClicked, setSubmitClicked] = useState(false);
  const [isPasswordShow, setIsPasswordShow] = useState(false);
  const onSubmit = (values: FormikValues) => {
    API_CALLS.AUTH.registerANewUser(
      values as AuthProps.RegisterANewUserRequest,
      navigate
    );
  };
  const redirectUrl = useRedirectUrl();

  useEffect(() => {
    dispatch(actions.auth.setResetPasswordAPI_STATUS("idle"));
  }, [dispatch]);
  useEffect(() => {
    if (!isAuthenticated) return;
    navigate(redirectUrl);
  }, [isAuthenticated, redirectUrl, navigate]);
  useAuthRedirect();

  return (
    <AuthLayout>
      <AuthCommonStyle.Wrapper>
        <Components.Text
          style={{
            tokenStyle: typography.Heading.L,
          }}
          text="Create an account"
          fontWeight="font-weight-semibold"
        />
        <AuthCommonStyle.Container>
          <AuthCommonStyle.SocialButton
            onClick={() =>
              window.open(API_PATHS.Authentication.socialAuthGoogle, "_self")
            }
          >
            <Icons.Google />
            <Components.Text
              style={{
                tokenStyle: typography.Body.L,
              }}
              text="Continue with Google"
            />
          </AuthCommonStyle.SocialButton>
          <AuthCommonStyle.SocialButton
            onClick={() =>
              window.open(API_PATHS.Authentication.socialAuthApple, "_self")
            }
          >
            <Icons.Apple />
            <Components.Text
              style={{
                tokenStyle: typography.Body.L,
              }}
              text="Continue with Apple"
            />
          </AuthCommonStyle.SocialButton>
        </AuthCommonStyle.Container>
      </AuthCommonStyle.Wrapper>
      <AuthCommonStyle.InfoContainer>
        <AuthCommonStyle.Divider />
        <Components.Text
          style={{
            tokenStyle: typography.Body.M,
          }}
          text="or Sign up with Email"
          color={Color.text["text-tertiary"].value}
        />
        <AuthCommonStyle.Divider />
      </AuthCommonStyle.InfoContainer>
      <Formik
        initialValues={{
          first_name: "",
          last_name: "",
          email: "",
          password: "",
        }}
        validationSchema={registerValidationSchema}
        onSubmit={onSubmit}
      >
        {(formik) => {
          const onKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
            const isEnterKey = e.key === "Enter";
            if (isEnterKey) formik.submitForm();
          };
          return (
            <AuthCommonStyle.Wrapper>
              <AuthCommonStyle.Container>
                <AuthCommonStyle.GroupContainer>
                  <Input
                    fieldName="first_name"
                    idPrefix="register"
                    isRequired
                    placeholder="First Name"
                    onKeyDown={onKeyDown}
                  />
                  <Input
                    fieldName="last_name"
                    idPrefix="register"
                    isRequired
                    placeholder="Last Name"
                    onKeyDown={onKeyDown}
                  />
                  <Input
                    idPrefix="register"
                    isRequired
                    placeholder="Email"
                    fieldName="email"
                    onKeyDown={onKeyDown}
                  />
                  <Input
                    idPrefix="register"
                    isRequired
                    placeholder="Password"
                    fieldName="password"
                    type={isPasswordShow ? "text" : "password"}
                    iconType={isPasswordShow ? "EyeOff" : "Eye"}
                    iconClickhandler={() => setIsPasswordShow(!isPasswordShow)}
                    onKeyDown={onKeyDown}
                  />
                </AuthCommonStyle.GroupContainer>
                <div>
                  <Components.Text
                    style={{ tokenStyle: typography.Body.S }}
                    text="By continuing you are agreeing to our "
                    color={Color.text["text-secondary"].value}
                  />
                  <NavLink to={ROUTES.termsAndCondition}>
                    <Components.Text
                      style={{ tokenStyle: typography.Body.S }}
                      text="Terms & Conditions"
                      color={Color.text["text-emphasize-brand"].value}
                      isunderlined
                    />
                  </NavLink>
                  <Components.Text
                    style={{ tokenStyle: typography.Body.S }}
                    text=" and "
                    color={Color.text["text-secondary"].value}
                  />
                  <NavLink to={ROUTES.privacyPolicy}>
                    <Components.Text
                      style={{ tokenStyle: typography.Body.S }}
                      text="Privacy Policy"
                      color={Color.text["text-emphasize-brand"].value}
                      isunderlined
                    />
                  </NavLink>
                </div>
              </AuthCommonStyle.Container>
              <AuthCommonStyle.GroupContainer>
                <Components.Button
                  color="Primary"
                  kind="Primary"
                  label="Register"
                  size="Large"
                  width="fill"
                  onClick={() => {
                    setSubmitClicked(true);
                    formik.isValid && formik.submitForm();
                  }}
                  disabled={
                    (isSubmitClicked && !formik.isValid) ||
                    registerAPI_STATUS === "progress" ||
                    Object.keys(formik.errors).length > 0
                  }
                />

                <center>
                  <Components.Text
                    style={{ tokenStyle: typography.Body.L }}
                    text="Already have an account? "
                    color={Color.text["text-secondary"].value}
                  />
                  <NavLink to={ROUTES.login}>
                    <Components.Text
                      style={{ tokenStyle: typography.Body.L }}
                      text="Sign in"
                      color={Color.text["text-emphasize-brand"].value}
                      isunderlined
                    />
                  </NavLink>
                </center>
              </AuthCommonStyle.GroupContainer>
            </AuthCommonStyle.Wrapper>
          );
        }}
      </Formik>
    </AuthLayout>
  );
};

export default Register;
