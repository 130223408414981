import { Strength } from "./PasswordStrengthMeter";
import { tokens } from "@thisgorilla/primate-ds";

export const checkPasswordStrength = (password: string) => {
  const weakPassword = /^(?:.{0,7}|[a-z]+$|\d+$|(.)(?:\1+)$)/;
  const averagePassword =
    /^(?=.*[A-Z])(?=.*[a-z])|(?=.*[A-Z])(?=.*\d)|(?=.*[A-Z])(?=.*[!@#$%^&*])|(?=.*[a-z])(?=.*\d)|(?=.*[a-z])(?=.*[!@#$%^&*])|(?=.*\d)(?=.*[!@#$%^&*]).{8,}$/;
  const strongPassword =
    /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[!@#$%^&*])(?!.*password)(?!.*123)(?!.*qwerty)(?=.{8,})/;

  if (strongPassword.test(password)) {
    return "strong";
  }
  if (averagePassword.test(password)) {
    return "average";
  }
  if (weakPassword.test(password)) {
    return "weak";
  }

  return "weak";
};

export const strengthWisePercentage = (strength: Strength) => {
  switch (strength) {
    case "strong":
      return 100;
    case "weak":
      return 33;
    case "average":
      return 66;
  }
};
export const strengthWiseBackground = (strength: Strength) => {
  const { light } = tokens;
  switch (strength) {
    case "strong":
      return `linear-gradient(90deg, ${light.background.negative.default.value} 0%,${light.background.warning.default.value} 33%, ${light.background.positive.default.value} 100%)`;
    case "weak":
      return light.background.negative.default.value;
    case "average":
      return `linear-gradient(90deg, ${light.background.negative.default.value} 0%,${light.background.warning.default.value} 100%)`;
  }
};
