import styled from "styled-components";

import { Header } from "./Header";
import { LayoutProps } from "./Layout";

export const Layout = ({ children }: LayoutProps.IProps) => {
  return (
    <Container>
      <Header />
      <ChildrenContainer>{children}</ChildrenContainer>
    </Container>
  );
};

const Container = styled.div`
  background: ${({ theme }) =>
    theme.Color.background.system["background-theme-2"].value};
  min-height: 100vh;
`;
const ChildrenContainer = styled.div`
  padding: 32px 50px 71px 66px;
`;
