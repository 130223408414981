import { Components } from "@thisgorilla/primate-ds";

export const validFileTypes = [
  "image/jpeg",
  "image/png",
  "image/webp",
  "image/avif",
];

export const validFile = (file: File) => {
  if (!validFileTypes.includes(file.type)) {
    Components.Toast.toastErrorMessage({
      message: `${file.name} is not valid extensions: [.jpeg, .png, .webp, .avif]`,
    });
    return false;
  }

  if (file.size > 10000000) {
    Components.Toast.toastErrorMessage({
      message: `${file.name} is too large. Max size is 10MB`,
    });
    return false;
  }

  return true;
};

export const getImageBase64 = (file: File) => {
  return new Promise<string>((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });
};
