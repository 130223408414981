import { useState } from "react";
import styled from "styled-components";
import { Components, ComponentsProps } from "@thisgorilla/primate-ds";

export const MenuItems = () => {
  const [selectedTab, setSelectedTab] =
    useState<HeaderProps.HeaderTabs>("Home");

  const buttonProps: ComponentsProps.ButtonsProps.IProps = {
    kind: "Tertiary",
    color: "Primary",
    disabled: false,
    size: "Medium",
    label: "Home",
    onClick: () => {},
    iconType: "Home",
    iconPosition: "left",
    isunderlined: false,
  };
  return (
    <Container>
      <Components.Button
        {...buttonProps}
        iconType={"Home"}
        label="Home"
        onClick={() => setSelectedTab("Home")}
        isunderlined={selectedTab === "Home"}
      />
      <Components.Button
        {...buttonProps}
        iconType={"Users"}
        label="My Network"
        onClick={() => setSelectedTab("My Network")}
        isunderlined={selectedTab === "My Network"}
      />
      <Components.Button
        {...buttonProps}
        iconType={"Briefcase"}
        label="Jobs"
        onClick={() => setSelectedTab("Jobs")}
        isunderlined={selectedTab === "Jobs"}
      />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-self: end;
  gap: 8px;
`;
