import { useRef, useState } from "react";
import { Components } from "@thisgorilla/primate-ds";
import styled, { css, useTheme } from "styled-components";

import { useAppSelector } from "../../../../../../../redux";
import { useClickOutside } from "../../../../../../../hooks/useClickOutside";
import { PostAsDropdown } from "../../../../../../Common";

const PostAs = () => {
  const ref = useRef(null);
  const { first_name, last_name, profile_picture } = useAppSelector(
    (state) => state.me
  );
  const userName = first_name + " " + last_name;
  const { typography, Color } = useTheme();
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(userName);

  useClickOutside({ ref, setState: setDropdownOpen });

  return (
    <PostAsContainer ref={ref}>
      <Container
        onClick={() => setDropdownOpen(!isDropdownOpen)}
        $isdropdownopen={isDropdownOpen}
      >
        <div>
          <Components.Avatar
            size="Large-56"
            src={profile_picture}
            type="Circle"
          />
        </div>
        <PostAsDetail>
          <NameContainer>
            <Components.Text
              style={{ tokenStyle: typography.Heading.S }}
              text={userName}
              fontWeight="font-weight-bold"
              maxLineLength={1}
            />
            <IconContainer>
              <Components.Icon
                type={isDropdownOpen ? "ChevronUp" : "ChevronDown"}
                size="16px"
              />
            </IconContainer>
          </NameContainer>
          <Components.Text
            style={{ tokenStyle: typography.Body.S }}
            text={
              "Post as " + (selectedOption === userName ? "me" : selectedOption)
            }
            color={Color.text["text-secondary"].value}
          />
        </PostAsDetail>
      </Container>
      {isDropdownOpen && (
        <PostAsDropdown
          top={76}
          selectedOption={selectedOption}
          setSelectedOption={setSelectedOption}
        />
      )}
    </PostAsContainer>
  );
};

export default PostAs;

const PostAsContainer = styled.div`
  position: relative;
`;

const activeButtoncss = css`
  border-radius: ${({ theme }) => theme.radius["radius-full-round"].value}px
    ${({ theme }) => theme.radius["radius-m"].value}px
    ${({ theme }) => theme.radius["radius-m"].value}px
    ${({ theme }) => theme.radius["radius-full-round"].value}px;
  background: ${({ theme }) =>
    theme.Color.elevation.surface["surface-primary-hover"].value};
`;

const Container = styled.div<{ $isdropdownopen: boolean }>`
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing["spacing-4"].value}px;
  padding: ${({ theme }) => theme.spacing["spacing-2"].value}px
    ${({ theme }) => theme.spacing["spacing-1"].value}px
    ${({ theme }) => theme.spacing["spacing-2"].value}px
    ${({ theme }) => theme.spacing["spacing-2"].value}px;

  &:hover {
    ${activeButtoncss}
  }

  ${({ $isdropdownopen }) => $isdropdownopen && activeButtoncss};
`;

const PostAsDetail = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing["spacing-1"].value}px;
  width: 100%;
`;
const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const NameContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: ${({ theme }) => theme.spacing["spacing-2"].value}px;
`;
