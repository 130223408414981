import instance from "../../axios/axios-user";
import { API_PATHS } from "../api_paths";

const getAllUsers = () => instance(API_PATHS.me.getAllUsers);
const getMentions = () => instance(API_PATHS.me.getMentions);
const getPeopleYouMightKnow = () =>
  instance(API_PATHS.me.getPeopleYouMightKnow);
const getRecommendedJobs = () => instance(API_PATHS.me.getRecommendedJobs);
const getSuggestedUsers = () => instance(API_PATHS.me.getSuggestedUsers);
const getUserInformation = () => instance(API_PATHS.me.getUserInformation);
const getYourInnerCircle = () => instance(API_PATHS.me.getYourInnerCircle);

export const ME = {
  getAllUsers,
  getMentions,
  getPeopleYouMightKnow,
  getRecommendedJobs,
  getSuggestedUsers,
  getUserInformation,
  getYourInnerCircle,
};
