import { createSlice } from "@reduxjs/toolkit";

import { MeProps } from "./Me";
import { setAPI_STATUS } from "../../common";
import { addProxyUrlToProfileImage } from "../../../utils";

const defaultUser: MeProps.User = {
  first_name: "",
  headline: "",
  id: "",
  last_name: "",
  profile_picture: "",
};
const defaultCommon: MeProps.Common = {
  allUsers: [],
  mentions: [],
  suggestedUsers: [],
  recommendedJobs: [],
  peopleYouMightKnow: [],
  yourInnerCircle: [],
};
const defaultAPI_STATUS: MeProps.API = {
  peopleYouMightKnow_API_STATUS: "idle",
  yourInnerCircle_API_STATUS: "idle",
};

const initialState: MeProps.State = {
  ...defaultUser,
  ...defaultCommon,
  ...defaultAPI_STATUS,
};

const slice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser: (state, action: MeProps.setUser) => {
      const { first_name, headline, id, last_name, profile_picture } =
        action.payload;

      state.first_name = first_name;
      state.headline = headline;
      state.id = id;
      state.last_name = last_name;
      state.profile_picture = addProxyUrlToProfileImage(profile_picture);
    },
    setPeopleYouMightKnow: (state, action: MeProps.setPeopleYouMightKnow) => {
      state.peopleYouMightKnow = action.payload;
    },
    setPeopleYouMightKnow_API_STATUS: (state, action: setAPI_STATUS) => {
      state.peopleYouMightKnow_API_STATUS = action.payload;
    },

    setYourInnerCircle: (state, action: MeProps.setYourInnerCircle) => {
      state.yourInnerCircle = action.payload;
    },
    setYourInnerCircleAPI_STATUS: (state, action: setAPI_STATUS) => {
      state.yourInnerCircle_API_STATUS = action.payload;
    },

    setMentions: (state, action: MeProps.setMentions) => {
      state.mentions = action.payload;
    },
    setSuggestedUsers: (state, action: MeProps.setSuggestedUsers) => {
      state.suggestedUsers = action.payload;
    },
    setAllUsers: (state, action: MeProps.setAllUsers) => {
      state.allUsers = action.payload;
    },

    setRecommendedJobs: (state, action: MeProps.setRecommendedJobs) => {
      state.recommendedJobs = action.payload;
    },

    reset: () => initialState,
  },
});

export const meActions = slice.actions;
export const meReducer = slice.reducer;
