import styled from "styled-components";

import ImgPreview from "./ImgPreview";
import { useAppSelector } from "../../../../../../../redux";

const MultiImagePreview = () => {
  const { uploadedImages, uploadedImagesUrls } = useAppSelector(
    (state) => state.posts
  );

  return (
    <Container>
      {uploadedImages.map((images, index) => (
        <ImgPreview
          id={images.id}
          key={images.id}
          src={uploadedImagesUrls[index]}
        />
      ))}
    </Container>
  );
};

export default MultiImagePreview;

const Container = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing["spacing-2"].value}px;
`;
