import styled from "styled-components";
import { Components } from "@thisgorilla/primate-ds";
import {
  Fragment,
  LegacyRef,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import { CarouselProps } from "./Carousel";

export const Carousel = ({
  carouselList,
  Component,
  height,
}: CarouselProps.IProps) => {
  const [current, setCurrent] = useState(1);
  const ref: LegacyRef<HTMLDivElement> = useRef(null);

  const TOTAL_DATA_PER_SLIDE = 3;
  const TOTAL_DATA_LENGTH = carouselList?.length;
  const TOTAL_SLIDES = Math.ceil(TOTAL_DATA_LENGTH / TOTAL_DATA_PER_SLIDE);

  const newCarouselList = useMemo(() => {
    let carouselData = [];
    let singleCarouselSlideData: any[] = [];
    for (let slideItem = 0; slideItem < TOTAL_DATA_LENGTH; slideItem++) {
      if (singleCarouselSlideData.length === TOTAL_DATA_PER_SLIDE) {
        carouselData.push(singleCarouselSlideData);
        singleCarouselSlideData = [];
        singleCarouselSlideData.push(carouselList[slideItem]);
        slideItem + 1 === TOTAL_DATA_LENGTH &&
          carouselData.push(singleCarouselSlideData);
      } else {
        singleCarouselSlideData.push(carouselList[slideItem]);
        slideItem + 1 === TOTAL_DATA_LENGTH &&
          carouselData.push(singleCarouselSlideData);
      }
    }
    return carouselData;
  }, [TOTAL_DATA_LENGTH, carouselList]);

  useEffect(() => {
    if (ref.current) {
      ref.current.style.transition = "all 0.8s ease-in-out";
      ref.current.style.transform = `translateX(-${current - 1}00%)`;
    }
  }, [current]);

  return (
    <>
      <CarouselSlideContainer>
        <CarouselSlide ref={ref}>
          {newCarouselList.map((carouselListPerSlide, index) => (
            <Wrapper key={`carousel-slide-${index}`} $height={height}>
              {carouselListPerSlide.map((carouselItem, index) => (
                <Fragment key={`carousel-item-${index}`}>
                  <Component {...carouselItem} />
                  {index !== carouselListPerSlide.length - 1 && <Divider />}
                </Fragment>
              ))}
            </Wrapper>
          ))}
        </CarouselSlide>
      </CarouselSlideContainer>
      <StepperContainer>
        {TOTAL_DATA_LENGTH > 0 && (
          <Components.Stepper
            onColor="On White"
            selectedStep={current}
            steps={TOTAL_SLIDES}
            setStepNumber={setCurrent}
            view="Gallery"
            leftLabel="Prev"
            rightLabel="Next"
          />
        )}
      </StepperContainer>
    </>
  );
};

const StepperContainer = styled.div`
  width: fit-content;
  margin: 0 auto;
`;

const Wrapper = styled.div<{ $height: number }>`
  display: flex;
  flex-direction: column;
  min-width: 316px;
  gap: ${({ theme }) => theme.spacing["spacing-4"].value}px;
  min-height: ${({ $height }) => $height}px;
`;

const Divider = styled.div`
  height: 1px;
  width: 100%;
  background: ${({ theme }) => theme.Color.border["ui-border-subtle"].value};
`;

const CarouselSlideContainer = styled.div`
  width: 100%;
  overflow: hidden;
`;

export const CarouselSlide = styled.div`
  display: flex;
`;
