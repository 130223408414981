import styled from "styled-components";
import { Field, FieldProps } from "formik";
import { useContext, useState } from "react";
import { Inputs } from "@thisgorilla/primate-ds";

import CreatePoll from "./CreatePoll";
import UrlPreview from "./UrlPreview";
import { findCustomURL } from "./utils";
import ImageUpload from "./ImageUpload";
import { CreatePostModalContext } from "..";
import instance from "../../../../../../axios/axios-user";
import { RICHTEXT_INITIAL_VALUE } from "../utils";

const ModalContent = () => {
  const [isPreviewShow, setIsPreviewShow] = useState(true);
  const { pollOpen, setUrlData, urlData,imageUploadOpen } = useContext(CreatePostModalContext);

  const fieldNameState = "editorState";
  const fieldNameContent = "content";

  return (
    <ModalContentContainer>
      <Field key={fieldNameState} name={fieldNameState}>
        {({ field, form }: FieldProps) => {
          const { value } = field;
          const { setFieldValue, validateField, errors } = form;

          return (
            <Inputs.RichTextarea
              axiosInstance={instance}
              fieldName={fieldNameState}
              idPrefix="richtext"
              placeholder="Start typing"
              minHeight={196}
              isRequired
              mentionList={[]}
              helpTextKind={errors.content ? "Error" : undefined}
              helpText={errors.content as string}
              onBlur={() => {
                validateField(fieldNameContent);
              }}
              value={JSON.stringify(value?.toJSON())}
              onChangeHandler={(state, html) => {
                setUrlData(findCustomURL(state.toJSON().root));

                if (!html || html === RICHTEXT_INITIAL_VALUE) {
                  setFieldValue(fieldNameState, null);
                  setFieldValue(fieldNameContent, "");
                } else {
                  setFieldValue(fieldNameState, state);
                  setFieldValue(fieldNameContent, html);
                  setTimeout(() => {
                    validateField(fieldNameContent);
                  }, 0);
                }
              }}
            />
          );
        }}
      </Field>

      {urlData && isPreviewShow && (
        <UrlPreview setIsPreviewShow={setIsPreviewShow} />
      )}
      {pollOpen && <CreatePoll />}
      {imageUploadOpen && <ImageUpload />}
    </ModalContentContainer>
  );
};

export default ModalContent;

const ModalContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing["spacing-4"].value}px;
  max-height: calc(100vh - 277px);
  overflow-y: auto;
`;
