import styled from "styled-components";
import { useContext, useState } from "react";
import { Components } from "@thisgorilla/primate-ds";

import { CreatePostModalContext } from "..";
import { CreatePostModalProps } from "../CreatePostModal";

const ModalFooter = () => {
  const [submitClicked, setSubmitClicked] = useState(false);
  const { setPollOpen, formik, setImageUploadOpen } = useContext(
    CreatePostModalContext
  );

  const footerLeftSideOptions: CreatePostModalProps.FooterLeftSideOption[] = [
    {
      iconType: "Image",
      onClick: () => {
        setImageUploadOpen(true);
      },
      tooltipLabel: "Add media",
    },
    { iconType: "Gif", onClick: () => {}, tooltipLabel: "Add a GIF" },
    { iconType: "Smile", onClick: () => {}, tooltipLabel: "Add emoji" },
    {
      iconType: "Poll",
      onClick: () => {
        setPollOpen(true);
        setTimeout(() => {
          formik.validateField("content");
        }, 0);
      },
      tooltipLabel: "Create a poll",
    },
    { iconType: "Slider", onClick: () => {}, tooltipLabel: "Create a slider" },
    {
      iconType: "FileText",
      onClick: () => {},
      tooltipLabel: "Write an article",
    },
  ];

  return (
    <ModalFooterContainer>
      <FooterLeftSide>
        {footerLeftSideOptions.map(
          ({ iconType, onClick, tooltipLabel }, index) => (
            <Components.Tooltip label={tooltipLabel} position="top" key={index}>
              <Components.IconButton
                iconType={iconType}
                onClick={onClick}
                disabled={false}
                kind="Secondary"
                size="Medium"
              />
            </Components.Tooltip>
          )
        )}
      </FooterLeftSide>
      <FooterRightSide>
        <Components.Button
          color="Neutral"
          kind="Secondary"
          disabled={false}
          label="Topics"
          onClick={() => {}}
          size="Medium"
          iconPosition="left"
          iconType="PlusCircle"
        />
        <Components.Tooltip label="Schedule post" position="top">
          <Components.IconButton
            iconType="Calendar"
            onClick={() => {}}
            disabled={false}
            kind="Secondary"
            size="Medium"
          />
        </Components.Tooltip>
        <Components.Button
          color="Primary"
          kind="Primary"
          label="Post"
          onClick={() => {
            setSubmitClicked(true);
            formik.isValid && formik.submitForm();
          }}
          disabled={
            (submitClicked && !formik.isValid) ||
            Object.keys(formik.errors).length > 0
          }
          size="Medium"
        />
      </FooterRightSide>
    </ModalFooterContainer>
  );
};

export default ModalFooter;

const ModalFooterContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: ${({ theme }) => theme.spacing["spacing-4"].value}px;
`;

const FooterLeftSide = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing["spacing-2"].value}px;
`;
const FooterRightSide = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing["spacing-4"].value}px;
`;
