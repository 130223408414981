// hooks/useClickOutside.ts
import React, { Dispatch, SetStateAction, useEffect } from "react";

type useClickOutsideProps = {
  ref: React.MutableRefObject<HTMLDivElement | null>;
  setState: Dispatch<SetStateAction<boolean>>;
};
const useClickOutside = ({ ref, setState }: useClickOutsideProps) => {
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const element = ref.current;
      if (!element) return;

      const isClickedWithinDropdown = element.contains(event.target as Node);
      if (isClickedWithinDropdown) return;

      setState(false);
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref, setState]);
};

export { useClickOutside };
