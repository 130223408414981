import styled, { useTheme } from "styled-components";

import { HomeStyles } from "../home.styled";

import { UserDetails } from "./1-UserDetails";
import { FollowersFollowing } from "./2-FollowersFollowing";
import { PeopleYouMightKnow } from "./3-PeopleYouMightKnow";
import { SavedItems } from "./4-SavedItems";
import { InnerCircle } from "./5-InnerCircle";
import { Troops } from "./6-Troops";
import { Divider } from "../../../../commonStyles";

export const LeftPanel = () => {
  const { Color } = useTheme();
  return (
    <Container className="left-panel">
      <UserDetails />
      <Divider $color={Color.border["ui-border-subtle"].value} />
      <FollowersFollowing />
      <Divider $color={Color.border["ui-border-subtle"].value} />
      <PeopleYouMightKnow />
      <Divider $color={Color.border["ui-border-subtle"].value} />
      <SavedItems />
      <Divider $color={Color.border["ui-border-subtle"].value} />
      <InnerCircle />
      <Divider $color={Color.border["ui-border-subtle"].value} />
      <Troops />
    </Container>
  );
};

const Container = styled.div`
  ${HomeStyles.defaultPanelContainer}
`;
