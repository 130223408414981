import styled from "styled-components";
import { Counter } from "./Counter";
import { useAppSelector } from "../../../../../redux";
import { useEffect } from "react";
import { API_CALLS } from "../../../../../API/api_calls";

export const FollowersFollowing = () => {
  const { followers_count, following_count, follow_count_API_STATUS } =
    useAppSelector((state) => state.connections);

  useEffect(() => {
    if (follow_count_API_STATUS !== "idle") return;

    API_CALLS.CONNECTIONS.getFollowCounts();
  }, [follow_count_API_STATUS]);

  return (
    <Container>
      <Counter title="Followers" count={followers_count} />
      <Counter title="Following" count={following_count} />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: row;
`;
