import { DragEvent, useContext } from "react";
import { Components } from "@thisgorilla/primate-ds";
import styled, { useTheme } from "styled-components";

import { CreatePostModalContext } from "../..";
import { ImageUploadProps } from "./ImageUpload";

const MediaUpload = ({
  uploadFile,
  fileInputRef,
}: ImageUploadProps.MediaUpload) => {
  const { typography } = useTheme();
  const { setImageUploadOpen } = useContext(CreatePostModalContext);

  const onImageDrop = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    const files = e.dataTransfer.files;
    if (files.length) {
      uploadFile(files);
    }
  };

  return (
    <Container onDrop={onImageDrop} onDragOver={(e) => e.preventDefault()}>
      <Components.IconButton
        kind="Primary"
        size="Large"
        disabled={false}
        iconType="Image"
        onClick={() => fileInputRef?.current?.click()}
      />
      <Components.Text
        style={{ tokenStyle: typography.Heading.S }}
        text="Add images"
        fontWeight="font-weight-semibold"
        lineHeight="line-height-400"
      />
      <Components.Text
        style={{ tokenStyle: typography.Body.S }}
        text="Or drag and drop here"
        fontWeight="font-weight-semibold"
        lineHeight="line-height-50"
      />
      <ButtonContainer>
        <Components.IconButton
          disabled={false}
          iconType="X"
          kind="Secondary"
          onClick={() => {
            setImageUploadOpen(false);
          }}
          size="Small"
        />
      </ButtonContainer>
    </Container>
  );
};

export default MediaUpload;

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
  padding: 47px ${({ theme }) => theme.spacing["spacing-4"].value}px;
  border-radius: ${({ theme }) => theme.radius["radius-xs"].value}px;
  border: 1px dashed
    ${({ theme }) => theme.Color.border["ui-border-emphasize-brand"].value};
  background: ${({ theme }) =>
    theme.Color.elevation.surface["surface-primary-emphasize-hover"].value};

  & > button {
    background: ${({ theme }) =>
      theme.Color.background.brand.subtle.default.value};
  }
`;

const ButtonContainer = styled.div`
  position: absolute;
  top: ${({ theme }) => theme.spacing["spacing-2"].value}px;
  right: ${({ theme }) => theme.spacing["spacing-2"].value}px;
`;
