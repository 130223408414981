import { API_PATHS } from "../api_paths";
import instance from "../../axios/axios-user";
import { PostsProps } from "../../redux/slices/posts/Posts";

const createPostDraft = () =>
  instance(API_PATHS.posts.createPostDraft, { method: "POST" });

const activateAPost = (data: PostsProps.activateAPostPayload) =>
  instance(API_PATHS.posts.activateAPost, { method: "POST", data });

const repostAPost = (data: PostsProps.repostAPostPayload) =>
  instance(API_PATHS.posts.repostAPost, { method: "POST", data });

const getSinglePost = (data: PostsProps.getSinglePostPayload) =>
  instance(API_PATHS.posts.post + data.post_id);

const uploadsImageForAPost = (data: PostsProps.uploadsImageForAPostPayload) =>
  instance(API_PATHS.posts.post + data.post_id + "/image", { method: "POST" });

const deleteAnImageForAPost = (data: PostsProps.deleteAnImageForAPostPayload) =>
  instance(API_PATHS.posts.post + data.post_id + "/image/" + data.image_id, {
    method: "DELETE",
  });

const getUserPosts = (data: PostsProps.getUserPostsPayload) =>
  instance(API_PATHS.posts.userPosts, { data });

export const POSTS = {
  createPostDraft,
  activateAPost,
  repostAPost,
  getSinglePost,
  uploadsImageForAPost,
  deleteAnImageForAPost,
  getUserPosts,
};
