import styled, { useTheme } from "styled-components";

import { LeftPanelCommonStyles } from "../Common";
import { getHexValueForPercentage } from "../../../../../utils";
import { TitleAndButton } from "./TitleAndButton";
import { ExistingTroops } from "./ExistingTroops";
import { RecommendedTroops } from "./RecommendedTroops";
import { Divider } from "../../../../../commonStyles";

export const Troops = () => {
  const { Color } = useTheme();
  return (
    <Container>
      <TitleAndButton />
      <Divider $color={Color.border["ui-border-subtle"].value} />
      <ExistingTroops />
      <Divider $color={Color.border["ui-border-subtle"].value} />
      <RecommendedTroops />
    </Container>
  );
};

const Container = styled.div`
  ${LeftPanelCommonStyles.getGradientBackgroundComponent(
    ` #09D195${getHexValueForPercentage(20)}, #FFFFFF 60%`
  )};
`;
