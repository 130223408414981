import { useTheme } from "styled-components";
import { Components } from "@thisgorilla/primate-ds";

import { JobCardStyles } from "./JobCard.styled";
import { MeProps } from "../../../redux/slices/me/Me";
import { calculateTimePassed } from "../../../utils";

const JobCard = (props: MeProps.RecommendedJob) => {
  const { Color, typography } = useTheme();
  return (
    <JobCardStyles.Container>
      <Components.Avatar
        disabled={false}
        size="Large-56"
        src={props.picture}
        type="Square-Rounded"
      />
      <JobCardStyles.JobInfoContainer>
        <JobCardStyles.TitleContainer>
          <Components.Text
            text={props.title}
            maxLineLength={1}
            style={{
              tokenStyle: {
                ...typography.Heading.XXS,
                "line-height": "16px",
              },
            }}
            fontWeight="font-weight-semibold"
          />
          <Components.Text
            text={calculateTimePassed(props.created_at)}
            style={{
              tokenStyle: typography.Body.XS,
            }}
            color={Color.text["text-tertiary"].value}
          />
        </JobCardStyles.TitleContainer>
        <Components.Text
          text={props.company}
          maxLineLength={1}
          style={{
            tokenStyle: typography.Body.S,
          }}
          color={Color.text["text-secondary"].value}
        />
        <Components.Text
          text={props.location}
          maxLineLength={1}
          style={{
            tokenStyle: typography.Body.S,
          }}
          color={Color.text["text-tertiary"].value}
        />
      </JobCardStyles.JobInfoContainer>
    </JobCardStyles.Container>
  );
};

export default JobCard;
