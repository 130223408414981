import { useEffect, useRef, useState } from "react";
import { Components } from "@thisgorilla/primate-ds";
import styled from "styled-components";

import { useClickOutside } from "../../../../../../hooks/useClickOutside";
import { PostAsDropdown } from "../../../../../Common";
import { useAppSelector } from "../../../../../../redux";

export const PostAs = () => {
  const ref = useRef(null);
  const { first_name, last_name } = useAppSelector((state) => state.me);
  const userName = first_name + " " + last_name;
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(userName);
  useClickOutside({ ref, setState: setDropdownOpen });

  useEffect(() => {
    setSelectedOption(userName);
  }, [userName]);

  return (
    <Container ref={ref} className="post-as">
      <Components.Button
        kind="Secondary"
        size="Medium"
        color="Neutral"
        disabled={false}
        label={
          "Post as " + (selectedOption === userName ? "me" : selectedOption)
        }
        iconType={isDropdownOpen ? "ChevronUp" : "ChevronDown"}
        iconPosition="right"
        onClick={() => setDropdownOpen((prev) => !prev)}
      />
      {isDropdownOpen && (
        <PostAsDropdown
          top={44}
          selectedOption={selectedOption}
          setSelectedOption={setSelectedOption}
        />
      )}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  position: relative;
  transition: height 0.3s;
`;
