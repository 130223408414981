import { Components } from "@thisgorilla/primate-ds";

const getErrorMessage = (error: any) => {
  return error?.response?.data?.error;
};

const getErrorMessageTitle = (functionName: string) => {
  const splittedWords = functionName
    .match(/[A-Z]?[a-z]+/g)
    ?.map((word) => word.toLowerCase());
  return splittedWords?.join(" ");
};

export const handleError = (error: any, functionName: string) => {
  const errorMessage = getErrorMessage(error);
  const wordsFromFunction = getErrorMessageTitle(functionName);

  let message = errorMessage;
  if (Array.isArray(wordsFromFunction)) {
    message = `Error while ${wordsFromFunction} ${errorMessage}`;
  }

  Components.Toast.toastErrorMessage({ message });
};
