import { Components } from "@thisgorilla/primate-ds";
import styled, { useTheme } from "styled-components";
import { useAppSelector } from "../../../../../redux";

export const UserDetails = () => {
  const { profile_picture, first_name, last_name, headline } = useAppSelector(
    (state) => state.me
  );
  const { typography, Color } = useTheme();
  const name = `${first_name} ${last_name}`;

  return (
    <Container className="user-details">
      <Components.Avatar
        type="Circle"
        disabled={false}
        size="Small-40"
        src={profile_picture}
        alt="Profile Picture"
      />
      <NameAndTitle>
        <Components.Text
          style={{ tokenStyle: typography.Body.L }}
          text={name}
          fontWeight="font-weight-bold"
        />
        <Components.Text
          text={headline}
          style={{ tokenStyle: typography.Body.S }}
          color={Color.text["text-secondary"].value}
        />
      </NameAndTitle>
    </Container>
  );
};

const Container = styled.div(
  ({ theme }) => `
  border-radius: ${theme.spacing["spacing-2"].value};
  padding: ${theme.spacing["spacing-3"].value}px ${theme.spacing["spacing-2"].value}px;

  display: flex;
  flex-direction: row;
  gap: ${theme.spacing["spacing-2"].value}px;
`
);
const NameAndTitle = styled.div`
  display: flex;
  flex-direction: column;
`;
