import styled from "styled-components";
import { Components, ComponentsProps } from "@thisgorilla/primate-ds";
import { ListItemProps } from "@thisgorilla/primate-ds/dist/components/ListItem";

import { useAppSelector } from "../../../redux";
import { PostAsDropdownProps } from "./PostAsDropdown";

export const PostAsDropdown = ({
  setSelectedOption,
  selectedOption,
  top,
}: PostAsDropdownProps.IProps) => {
  const { first_name, last_name, profile_picture } = useAppSelector(
    (state) => state.me
  );
  const userName = first_name + " " + last_name;
  const companyName = "Company Name";
  const troopName = "Troop";

  const onClick = (
    option: ComponentsProps.ListItemProps.DefaultListItemProps
  ) => {
    setSelectedOption(option.label);
  };

  const menuLists: ListItemProps.IProps[] = [
    { type: "Label", label: "Post as" },
    {
      type: "List Item",
      label: userName,
      disabled: false,
      radio: true,
      avatarSrc: profile_picture,
      avatarType: "Circle",
      selected: selectedOption === userName,
      onClick,
      renderInSingleLine: true,
    },
    { type: "Divider" },
    {
      type: "List Item",
      label: companyName,
      disabled: false,
      radio: true,
      avatarSrc: "/images/common/Gorilla.png",
      avatarType: "Square-Rounded",
      selected: selectedOption === companyName,
      onClick,
      renderInSingleLine: true,
    },
    { type: "Divider" },
    {
      type: "List Item",
      label: troopName,
      disabled: false,
      radio: true,
      avatarSrc: "/images/common/Gorilla.png",
      avatarType: "Square-Rounded",
      selected: selectedOption === troopName,
      onClick,
      renderInSingleLine: true,
    },
    { type: "Divider" },
    { type: "Label", label: "Add" },
    {
      type: "Button",
      label: "Troop",
      IconType: "Plus",
      disabled: false,
      onClick: () => console.log("Troop"),
    },
    {
      type: "Button",
      label: "Company",
      IconType: "Plus",
      disabled: false,
      onClick: () => console.log("Company"),
    },
  ];

  return (
    <Container $top={top}>
      <Components.Menu size="fill" itemGap={4}>
        {menuLists.map((item, index) => (
          <Components.ListItem {...item} key={index} />
        ))}
      </Components.Menu>
    </Container>
  );
};

const Container = styled.div<{ $top: number }>`
  position: absolute;
  top: ${({ $top }) => $top}px;
  right: 0;
  z-index: 10;
  border-radius: ${({ theme }) => theme.radius["radius-xs"].value}px;
  background: ${({ theme }) =>
    theme.Color.elevation.surface["surface-primary"].value};
`;
