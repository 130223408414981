import { KeyboardEvent, useEffect, useState } from "react";
import { useTheme } from "styled-components";
import { Formik, FormikValues } from "formik";
import { useNavigate } from "react-router-dom";
import { Components } from "@thisgorilla/primate-ds";

import Input from "../../../Input";
import { AuthCommonStyle } from "../../style";
import { forgotValidationSchema } from "../utils";
import { ROUTES } from "../../../../utills/routes";
import { actions, useAppDispatch, useAppSelector } from "../../../../redux";
import { API_CALLS } from "../../../../API/api_calls";

const Forgot = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { typography } = useTheme();
  const [submitClicked, setSubmitClicked] = useState(false);
  const { forgotPasswordAPI_STATUS } = useAppSelector((state) => state.auth);

  const onSubmit = (values: FormikValues) => {
    API_CALLS.AUTH.forgotPassword(values.email);
  };

  useEffect(() => {
    dispatch(actions.auth.setForgotPasswordAPI_STATUS("idle"));
  }, [dispatch]);

  return (
    <AuthCommonStyle.Wrapper>
      <Components.Text
        style={{
          tokenStyle: typography.Heading.L,
        }}
        text="Forgot your password?"
        fontWeight="font-weight-semibold"
      />
      <Formik
        initialValues={{
          email: "",
        }}
        validationSchema={forgotValidationSchema}
        onSubmit={onSubmit}
      >
        {(formik) => {
          const onKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
            const isEnterKey = e.key === "Enter";
            if (isEnterKey) formik.submitForm();
          };
          return (
            <AuthCommonStyle.Container>
              <AuthCommonStyle.GroupContainer>
                <Input
                  fieldName="email"
                  placeholder="Email"
                  idPrefix="forgot"
                  isRequired
                  onKeyDown={onKeyDown}
                />
              </AuthCommonStyle.GroupContainer>
              <AuthCommonStyle.GroupContainer>
                <Components.Button
                  color="Primary"
                  kind="Primary"
                  label="Reset password"
                  size="Large"
                  width="fill"
                  onClick={() => {
                    setSubmitClicked(true);
                    formik.isValid && formik.submitForm();
                  }}
                  disabled={
                    (submitClicked && !formik.isValid) ||
                    forgotPasswordAPI_STATUS === "progress" ||
                    Object.keys(formik.errors).length > 0
                  }
                />
                <Components.Button
                  color="Primary"
                  kind="Tertiary"
                  label="Cancel"
                  size="Large"
                  width="fill"
                  disabled={false}
                  onClick={() => navigate(ROUTES.login)}
                />
              </AuthCommonStyle.GroupContainer>
            </AuthCommonStyle.Container>
          );
        }}
      </Formik>
    </AuthCommonStyle.Wrapper>
  );
};

export default Forgot;
