import styled from "styled-components";

import { Profile } from "./Profile";
import { Notifications } from "./Notifications";

export const ProfileAndNotifications = () => {
  return (
    <Container>
      <Notifications />
      <Profile />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
  padding: 4px;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) =>
    theme.Color.elevation.surface["surface-secondary"].value};
  border-radius: 4px 30px 30px 4px;
`;
