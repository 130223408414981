import { useState } from "react";
import styled, { useTheme } from "styled-components";
import { Components, ComponentsProps } from "@thisgorilla/primate-ds";
import { ListItemProps } from "@thisgorilla/primate-ds/dist/components/ListItem";

import { CreatePostModalProps } from "../../CreatePostModal";

export const PostToDropdown = ({
  setSelectedOption,
  selectedOption,
}: CreatePostModalProps.PostToDropdown) => {
  const { typography, Color } = useTheme();
  const [isShowAll, setShowAll] = useState(false);
  const [selectedCircleOption, setSelectedCircleOption] = useState<string[]>(
    []
  );

  const onClick = (
    option: ComponentsProps.ListItemProps.DefaultListItemProps
  ) => {
    setSelectedOption(option);
  };

  const menuLists: ListItemProps.IProps[] = [
    { type: "Label", label: "Post to" },
    {
      type: "List Item",
      label: "Everyone/Public",
      disabled: false,
      iconType: "Globe",
      selected: (selectedOption?.label ?? "") === "Everyone/Public",
      onClick,
    },
    {
      type: "List Item",
      label: "Private",
      disabled: false,
      iconType: "EyeOff",
      selected: (selectedOption?.label ?? "") === "Private",
      onClick,
    },
    {
      type: "List Item",
      label: "Followers",
      disabled: false,
      iconType: "UserPlus",
      selected: (selectedOption?.label ?? "") === "Followers",
      onClick,
    },
  ];
  const circleOnClick = (
    option: ComponentsProps.ListItemProps.CircleListItemProps
  ) => {
    if (selectedCircleOption.includes(option.label)) {
      setSelectedCircleOption((prev) =>
        prev.filter((item) => item !== option.label)
      );
    } else {
      setSelectedCircleOption((prev) => [...prev, option.label]);
    }
  };
  const circleList: ComponentsProps.ListItemProps.IProps[] = [
    {
      type: "Circle",
      color: "#8B33FA",
      label: "Product Design tips",
      onClick: circleOnClick,
    },
    {
      type: "Circle",
      color: "#F68229",
      label: "The Kennel",
      onClick: circleOnClick,
    },
    {
      type: "Circle",
      color: Color.background.negative.default.value,
      label: "Controversial",
      onClick: circleOnClick,
    },
    {
      type: "Circle",
      color: "#287705",
      label: "Finance",
      onClick: circleOnClick,
    },
    {
      type: "Circle",
      color: "#948007",
      label: "Front-End",
      onClick: circleOnClick,
    },
    {
      type: "Circle",
      color: "#054D3A",
      label: "Service Design",
      onClick: circleOnClick,
    },
    {
      type: "Circle",
      color: "#444C2B",
      label: "Memes",
      onClick: circleOnClick,
    },
    {
      type: "Circle",
      color: "#068F67",
      label: "Business",
      onClick: circleOnClick,
    },
    {
      type: "Circle",
      color: Color.background.brand.default.value,
      label: "Markets",
      onClick: circleOnClick,
    },
    {
      type: "Circle",
      color: "#F85F82",
      label: "Mates",
      onClick: circleOnClick,
    },
    {
      type: "Circle",
      color: "#6A5B04",
      label: "Figma",
      onClick: circleOnClick,
    },
    {
      type: "Circle",
      color: "#5F06CE",
      label: "Design Systems",
      onClick: circleOnClick,
    },
  ];

  return (
    <Container $showall={isShowAll}>
      <Components.Menu size="fill" itemGap={8}>
        <div>
          {menuLists.map((item, index) => (
            <Components.ListItem {...item} key={index} />
          ))}
        </div>
        <Components.ListItem type="Divider" />
        <CircleContainer>
          <CircleHeading>
            <Components.Text
              text="Circles"
              style={{ tokenStyle: typography.Body.S }}
              fontWeight="font-weight-semibold"
              color={Color.text["text-emphasize-brand-bolder"].value}
            />
            <Components.Label text="Most used" kind="Fill" color="Positive" />
          </CircleHeading>
          <CircleList $showall={isShowAll}>
            {circleList
              .slice(0, isShowAll ? circleList.length : 3)
              .map((item, index) => (
                <Components.ListItem
                  {...{
                    ...item,
                    selected: selectedCircleOption.includes(
                      (
                        item as ComponentsProps.ListItemProps.CircleListItemProps
                      )?.label ?? ""
                    ),
                    showSelected: false,
                  }}
                  key={index}
                />
              ))}
          </CircleList>
          <Components.Button
            onClick={() => setShowAll(!isShowAll)}
            color="Primary"
            disabled={false}
            kind="Tertiary"
            label={isShowAll ? "Show less" : "Show all"}
            size="XS"
            iconPosition="right"
            iconType={isShowAll ? "ArrowLeft" : "ArrowRight"}
            width="fill"
          />
        </CircleContainer>
      </Components.Menu>
    </Container>
  );
};

const Container = styled.div<{ $showall: boolean }>`
  position: absolute;
  top: 38px;
  left: 0;
  z-index: 10;
  border-radius: ${({ theme }) => theme.radius["radius-xs"].value}px;
  width: ${({ $showall }) => ($showall ? 403 : 280)}px;
  background: ${({ theme }) =>
    theme.Color.elevation.surface["surface-primary"].value};
`;
const CircleContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing["spacing-2"].value}px;
  padding: ${({ theme }) => theme.spacing["spacing-2"].value}px;
  border-radius: ${({ theme }) => theme.radius["radius-xxs"].value}px;
  background: linear-gradient(
    116deg,
    #f4fbff 21.57%,
    rgba(230, 236, 255, 0) 76.26%
  );

  button {
    justify-content: start;
  }
`;

const CircleList = styled.div<{ $showall: boolean }>`
  display: flex;
  gap: ${({ theme }) => theme.spacing["spacing-1"].value}px;
  flex-wrap: wrap;

  .list-item {
    width: ${({ $showall }) => ($showall ? `calc(50% - 2px)` : "100%")};
  }
`;

const CircleHeading = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
