import { useState } from "react";
import styled from "styled-components";
import { Inputs } from "@thisgorilla/primate-ds";

import UrlPreview from "./UrlPreview";
import { findCustomURL } from "./utils";
import { CreatePostModalProps } from "../CreatePostModal";
import instance from "../../../../../../axios/axios-user";

const ModalContent = ({
  urlData,
  setUrlData,
  setValue,
  value,
}: CreatePostModalProps.ModalContent) => {
  const [isPreviewShow, setIsPreviewShow] = useState(true);

  return (
    <ModalContentContainer>
      <Inputs.RichTextarea
        axiosInstance={instance}
        fieldName="richtext"
        idPrefix="richtext"
        placeholder="Start typing"
        minHeight={196}
        isRequired
        mentionList={[]}
        value={JSON.stringify(value.state?.toJSON())}
        onChangeHandler={(state, html) => {
          setUrlData(findCustomURL(state.toJSON().root));
          setValue({ state, html });
        }}
      />
      {urlData && isPreviewShow && (
        <UrlPreview urlData={urlData} setIsPreviewShow={setIsPreviewShow} />
      )}
    </ModalContentContainer>
  );
};

export default ModalContent;

const ModalContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing["spacing-4"].value}px;
  max-height: calc(100vh - 277px);
  overflow-y: auto;
`;
