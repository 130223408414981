import { meActions, meReducer } from "./me";
import { authActions, authReducer } from "./auth";
import { postsActions, postsReducer } from "./posts";
import { connectionsActions, connectionsReducer } from "./connections";
import { savedItemsActions, savedItemsReducer } from "./savedItems";

export type ReduxKeys = keyof typeof actions;

export const actions = {
  me: meActions,
  auth: authActions,
  posts: postsActions,
  connections: connectionsActions,
  savedItems: savedItemsActions,
};

export const reducer = {
  me: meReducer,
  auth: authReducer,
  posts: postsReducer,
  connections: connectionsReducer,
  savedItems: savedItemsReducer,
};
