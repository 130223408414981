import { Components } from "@thisgorilla/primate-ds";

import { LeftPanelCommonStyles } from "../Common";
import { useTheme } from "styled-components";

export const TitleAndButton = () => {
  const { typography, Color } = useTheme();

  return (
    <LeftPanelCommonStyles.TitleAndButton>
      <Components.Text
        text={"Troops"}
        style={{ tokenStyle: typography.Heading.S }}
        color={Color.text["text-emphasize-positive"].value}
        fontWeight="font-weight-semibold"
      />
      <Components.IconButton
        iconType="Plus"
        size="XS"
        kind="Secondary"
        disabled={false}
        onClick={() => {}}
      />
    </LeftPanelCommonStyles.TitleAndButton>
  );
};
