import { API_PATHS } from "../api_paths";
import instance from "../../axios/axios-user";
import { PollsProps } from "../../redux/slices/polls/Polls";

const createANewPoll = (data: PollsProps.CreateANewPollPayload) =>
  instance(API_PATHS.polls.CreateANewPoll, { method: "POST", data: data });

const showAPoll = (poll_id: string) =>
  instance(API_PATHS.polls.CreateANewPoll + `/${poll_id}`);

export const POLLS = { createANewPoll, showAPoll };
