import { Components } from "@thisgorilla/primate-ds";
import { ListItemProps } from "@thisgorilla/primate-ds/dist/components/ListItem1";
import styled from "styled-components";

type ListItem = Omit<ListItemProps.DefaultListItemProps, "label">;

export const ExistingTroops = () => {
  const commonProps: ListItem = {
    type: "List Item",
    onClick: () => {},
    selected: false,
    showSelected: false,
    iconType: "Users",
  } as const;

  return (
    <Container>
      {(
        [
          { ...commonProps, label: "Product Designers Cape Town" },
          { ...commonProps, label: "ZA Tech" },
          { ...commonProps, label: "Founders Squad Cape Town" },
        ] as ListItemProps.IProps[]
      ).map((listItem, i) => (
        <Components.ListItem {...listItem} key={i} />
      ))}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;
