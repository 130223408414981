import styled, { useTheme } from "styled-components";
import { Components } from "@thisgorilla/primate-ds";

import { useAppSelector } from "../../../../../../../redux";
import { API_CALLS } from "../../../../../../../API/api_calls";
import { useMouseOver } from "../../../../../../../hooks/useMouseOver";

const ImgPreview = ({ id, src }: { id: string; src: string }) => {
  const { post_id } = useAppSelector((state) => state.posts);
  const { isHovered } = useMouseOver({ id });
  const { Color } = useTheme();

  return (
    <ImageContainer id={id}>
      <Image $src={src} $hover={isHovered} />
      <DeleteButton $hover={isHovered}>
        <Components.IconButton
          disabled={false}
          iconType="Trash2"
          kind="Tertiary"
          onClick={() => {
            API_CALLS.POSTS.deleteAnImageForAPost({
              image_id: id,
              post_id,
            });
          }}
          size="XS"
          IconButtonColor={Color.elevation.surface["surface-overlay"].value}
        />
      </DeleteButton>
    </ImageContainer>
  );
};

export default ImgPreview;

const ImageContainer = styled.div`
  position: relative;
  cursor: pointer;
`;
const DeleteButton = styled.div<{ $hover: boolean }>`
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  display: ${({ $hover }) => ($hover ? "flex" : "none")};
  justify-content: center;
  align-items: center;
`;

const Image = styled.div<{ $src: string; $hover: boolean }>`
  display: block;
  width: 70px;
  height: 70px;
  border-radius: ${({ theme }) => theme.radius["radius-xs"].value}px;
  background: url(${({ $src }) => $src}) lightgray 50% / cover no-repeat;
  cursor: pointer;

  ${({ $hover, $src }) =>
    $hover &&
    ` background: linear-gradient(
        0deg,
        rgba(0, 0, 0, 0.5) 0%,
        rgba(0, 0, 0, 0.5) 100%
      ),
      url(${$src}) lightgray 50% / cover no-repeat;`}
`;
