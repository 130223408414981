import styled from "styled-components";

import UserInfo from "./UserInfo";
import UserFollowersInfo from "./UserFollowersInfo";
import { MeProps } from "../../../redux/slices/me/Me";

const UserCard = (props: MeProps.UserWithFollowersMetadata) => {
  return (
    <Container>
      <UserInfo {...props} />
      <UserFollowersInfo {...props} />
    </Container>
  );
};

export default UserCard;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  cursor: pointer;
  border-radius: ${({ theme }) => theme.radius["radius-xs"].value}px;
  padding: ${({ theme }) => theme.spacing["spacing-3"].value}px
    ${({ theme }) => theme.spacing["spacing-2"].value}px;
  &:hover {
    background: ${({ theme }) =>
      theme.Color.elevation.surface["surface-primary-hover"].value};
  }
`;
