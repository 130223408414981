import { ME } from "./me";
import { POSTS } from "./posts";
import { POLLS } from "./polls";
import { AUTH } from "./authentication";
import { SAVED_ITEMS } from "./savedItems";
import { CONNECTIONS } from "./connections";

export const API_CALLS = {
  ME,
  POSTS,
  POLLS,
  AUTH,
  SAVED_ITEMS,
  CONNECTIONS,
};
