import { Components } from "@thisgorilla/primate-ds";
import { useTheme } from "styled-components";

import { LeftPanelCommonStyles } from "../Common";

export const TitleAndButton = () => {
  const { typography, Color } = useTheme();

  return (
    <LeftPanelCommonStyles.TitleAndButton>
      <Components.Text
        style={{ tokenStyle: typography.Heading.S }}
        text="People you might know"
        color={Color.text["text-emphasize-brand-bolder"].value}
      />
      <Components.IconButton
        disabled={false}
        iconType="ArrowRight"
        kind="Secondary"
        size="XS"
        onClick={() => {}}
      />
    </LeftPanelCommonStyles.TitleAndButton>
  );
};
