import styled, { useTheme } from "styled-components";

import { getHexValueForPercentage } from "../../../../../utils";
import { LeftPanelCommonStyles } from "../Common";
import { TitleAndButton } from "./TitleAndButton";
import { AvatarsList } from "./AvatarsList";
import { Divider } from "../../../../../commonStyles";

export const InnerCircle = () => {
  const { Color } = useTheme();
  return (
    <Container>
      <TitleAndButton />
      <Divider $color={Color.border["ui-border-subtle"].value} />
      <AvatarsList />
    </Container>
  );
};

const Container = styled.div`
  ${LeftPanelCommonStyles.getGradientBackgroundComponent(
    ` #F68229${getHexValueForPercentage(10)} 20%, #FFFFFF 100%`
  )};
`;
