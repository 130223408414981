import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { ThemeProviderComponent } from "@thisgorilla/primate-ds";
import { ToastContainer } from "react-toastify";

import "./index.css";

import App from "./App";
import store from "./redux";
import reportWebVitals from "./reportWebVitals";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <ThemeProviderComponent>
    <Provider store={store}>
      <ToastContainer />
      <App />
    </Provider>
  </ThemeProviderComponent>
);

reportWebVitals();
