import { Components } from "@thisgorilla/primate-ds";
import styled from "styled-components";

import PostAs from "./PostAs";
import PostTo from "./PostTo";
import { CreatePostModalProps } from "../CreatePostModal";
import { enableScroll } from "../../../../../../utils";

const ModalHeader = ({ setCreatePostOpen }: CreatePostModalProps.IProps) => {
  return (
    <HeaderContainer>
      <PostAsAndToContainer>
        <PostAs />
        <Divider />
        <PostTo />
      </PostAsAndToContainer>
      <Components.IconButton
        disabled={false}
        iconType="X"
        kind="Tertiary"
        onClick={() => {
          setCreatePostOpen(false);
          enableScroll();
        }}
        size="Medium"
      />
    </HeaderContainer>
  );
};

export default ModalHeader;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: ${({ theme }) => theme.spacing["spacing-4"].value}px;
`;

const PostAsAndToContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 280px;
  width: 100%;
  gap: ${({ theme }) => theme.spacing["spacing-2"].value}px;
`;

const Divider = styled.div`
  width: 100%;
  height: 1px;
  background: ${({ theme }) => theme.Color.border["ui-border-neutral"].value};
`;
