import instance from "../../axios/axios-user";
import { AuthProps } from "../../redux/slices/auth/auth";
import { API_PATHS } from "../api_paths";

const login = (body: AuthProps.LoginRequest) => {
  const config = {
    method: "POST",
    url: API_PATHS.Authentication.login,
    data: body,
  };
  return instance(config);
};

const logout = () => {
  const config = {
    method: "DELETE",
    url: API_PATHS.Authentication.login,
  };
  return instance(config);
};

const registerANewUser = (registerValue: AuthProps.RegisterANewUserRequest) => {
  const config = {
    method: "POST",
    url: API_PATHS.Authentication.registerANewUser,
    data: registerValue,
  };
  return instance(config);
};

const getStatus = () => instance(API_PATHS.Authentication.getStatus);

const forgotPassword = (email: string) => {
  const config = {
    method: "POST",
    url: API_PATHS.Authentication.forgotPassword,
    data: { email },
  };
  return instance(config);
};

const resetPassword = (resetValue: AuthProps.ResetPasswordRequest) => {
  const config = {
    method: "POST",
    url: API_PATHS.Authentication.resetPassword,
    data: resetValue,
  };
  return instance(config);
};

const checkOtp = (checkOtpValue: AuthProps.CheckOtpRequest) => {
  const config = {
    method: "POST",
    url: API_PATHS.Authentication.checkOtp,
    data: checkOtpValue,
  };
  return instance(config);
};

const resendOtp = (email: string) => {
  const config = {
    method: "POST",
    url: API_PATHS.Authentication.resendOtp,
    data: { email },
  };
  return instance(config);
};

export const AUTH = {
  login,
  getStatus,
  registerANewUser,
  logout,
  forgotPassword,
  resetPassword,
  checkOtp,
  resendOtp,
};
