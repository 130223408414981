import styled from "styled-components";

import { AvatarAndText } from "./AvatarAndText";
import { PostAsAndAttachments } from "./PostAsAndAttachments";
import { HomeStyles } from "../../home.styled";

export const DefaultPost = () => {
  return (
    <Container>
      <AvatarAndText />
      <PostAsAndAttachments />
    </Container>
  );
};

const Container = styled.div`
  gap: 16px;
  padding: 20px;
  ${HomeStyles.defaultContainer}
`;
