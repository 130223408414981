import { useEffect } from "react";
import { useTheme } from "styled-components";
import { Components } from "@thisgorilla/primate-ds";

import { useAppSelector } from "../../../../redux";
import { API_CALLS } from "../../../../API/api_calls";
import { RightPanelStyles } from "./RightPanel.styled";
import { Carousel, JobCard, UserCard } from "../../../Common";

export const RightPanel = () => {
  const { typography } = useTheme();
  const { suggestedUsers, recommendedJobs } = useAppSelector(
    (state) => state.me
  );

  useEffect(() => {
    API_CALLS.ME.getSuggestedUsers();
    API_CALLS.ME.getRecommendedJobs();
  }, []);

  return (
    <RightPanelStyles.Container className="right-panel">
      <RightPanelStyles.Title>
        <Components.Text
          text="Suggested for you"
          fontWeight="font-weight-medium"
          style={{
            tokenStyle: typography.Heading.S,
          }}
        />
      </RightPanelStyles.Title>
      <Carousel
        carouselList={suggestedUsers}
        Component={UserCard}
        height={360}
      />
      <RightPanelStyles.Divider />
      <RightPanelStyles.Title>
        <Components.Text
          text="Jobs for you"
          fontWeight="font-weight-medium"
          style={{
            tokenStyle: typography.Heading.S,
          }}
        />
      </RightPanelStyles.Title>
      <Carousel
        carouselList={recommendedJobs}
        Component={JobCard}
        height={306}
      />
    </RightPanelStyles.Container>
  );
};
