import { Inputs } from "@thisgorilla/primate-ds";
import styled from "styled-components";

import { MenuItems } from "./MenuItems";
import { ProfileAndNotifications } from "./ProfileAndNotifications";
import { useAppSelector } from "../../../redux";
import { JoinNowAndSignInButtons } from "./JoinNowAndSignInButtons";

export const Header = () => {
  const { isAuthenticated } = useAppSelector((state) => state.auth);

  const loggedInRight = (
    <Right>
      <Inputs.Search
        fieldName="header-search"
        idPrefix="header"
        isRequired={false}
        onChangeHandler={() => {}}
        value=""
        placeholder="Search"
        size="Medium"
      />
      <ProfileAndNotifications />
    </Right>
  );
  const loggedOutRight = (
    <Right>
      <JoinNowAndSignInButtons />
    </Right>
  );

  return (
    <Container className="header-container">
      <Left className="header-left-container">
        <DittoLogo src="images/header/dittoLogo.svg" alt="Ditto " />
        {isAuthenticated && <MenuItems />}
      </Left>
      {isAuthenticated ? loggedInRight : loggedOutRight}
    </Container>
  );
};

const Container = styled.div`
  height: 72px;
  padding: 0 52px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  box-shadow: ${({ theme }) => theme.boxShadows["box-shadow-m"]};
  background-color: ${({ theme }) =>
    theme.Color.elevation.surface["surface-primary"].value};
`;
const DittoLogo = styled.img`
  height: 40px;
`;
const Left = styled.div`
  display: flex;
  flex-direction: row;
  gap: 98px;
  align-items: center;
`;
const Right = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 60px;
`;
