import { useRef, useState } from "react";
import styled, { useTheme } from "styled-components";
import { Components, ComponentsProps } from "@thisgorilla/primate-ds";

import { PostToDropdown } from "./PostToDropdown";
import { useClickOutside } from "../../../../../../../hooks/useClickOutside";

const PostTo = () => {
  const ref = useRef(null);
  const { typography, Color } = useTheme();
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState({
    label: "Everyone/Public",
    iconType: "Globe",
  } as ComponentsProps.ListItemProps.DefaultListItemProps);

  useClickOutside({ ref, setState: setDropdownOpen });

  return (
    <Container ref={ref}>
      <PostToContainer onClick={() => setDropdownOpen(!isDropdownOpen)}>
        <Wrapper>
          <Components.Text
            style={{ tokenStyle: typography.Body.S }}
            text="Post to"
            color={Color.text["text-secondary"].value}
          />
          <PostToValue>
            <Components.Icon
              type={selectedOption.iconType || "none"}
              size="16px"
            />
            <Components.Text
              style={{ tokenStyle: typography.Body.M }}
              text={selectedOption.label}
            />
          </PostToValue>
        </Wrapper>
        <Components.Icon
          type={isDropdownOpen ? "ChevronUp" : "ChevronDown"}
          size="16px"
        />
      </PostToContainer>
      {isDropdownOpen && (
        <PostToDropdown
          selectedOption={selectedOption}
          setSelectedOption={setSelectedOption}
        />
      )}
    </Container>
  );
};

export default PostTo;

const Container = styled.div`
  position: relative;
`;
const PostToContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  gap: ${({ theme }) => theme.spacing["spacing-2"].value}px;
  padding: ${({ theme }) => theme.spacing["spacing-1"].value}px;
  border-radius: ${({ theme }) => theme.radius["radius-xxs"].value}px;
  background: ${({ theme }) =>
    theme.Color.elevation.surface["surface-secondary"].value};
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
`;
const PostToValue = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing["spacing-2"].value}px;
  border: 1px solid
    ${({ theme }) => theme.Color.border["ui-border-neutral"].value};
  border-radius: ${({ theme }) => theme.radius["radius-xxs"].value}px;
  padding: ${({ theme }) => theme.spacing["spacing-05"].value}px
    ${({ theme }) => theme.spacing["spacing-1"].value}px;
`;
