import { createSlice } from "@reduxjs/toolkit";

import { PollsProps } from "./Polls";

const initialState = {
  createANewPollResponse: {},
} as PollsProps.State;

const slice = createSlice({
  name: "polls",
  initialState,
  reducers: {
    setCreateANewPollResponse: (
      state,
      action: PollsProps.setCreateANewPollResponse
    ) => {
      state.createANewPollResponse = action.payload;
    },
    reset: () => initialState,
  },
});

export const pollsActions = slice.actions;
export const pollsReducer = slice.reducer;
