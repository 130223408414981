import { Components } from "@thisgorilla/primate-ds";
import { ListItemProps } from "@thisgorilla/primate-ds/dist/components/ListItem1";
import styled, { useTheme } from "styled-components";

type ListItem = ListItemProps.IProps;
export const RecommendedTroops = () => {
  const { typography, Color } = useTheme();

  const commonProps = {
    type: "List Item",
    onClick: () => {},
    selected: false,
    iconType: "Users",
    showRightButton: true,
    rightButtonLabel: "Join",
    rightButtonOnClick: () => {},
  } as const;
  const option1: ListItem = { label: "Startup Club ZA", ...commonProps };
  const option2: ListItem = { label: "Figma", ...commonProps };
  const option3: ListItem = {
    label: "Product Design Worldwide",
    ...commonProps,
  };

  return (
    <Container>
      <RecommendedTitle>
        <Components.Text
          text={"Recommended"}
          style={{ tokenStyle: typography.Body.M }}
          color={Color.text["text-on-bold"].value}
        />
      </RecommendedTitle>
      <Container>
        {[option1, option2, option3].map((listItem, i) => (
          <Components.ListItem {...listItem} key={i} />
        ))}
      </Container>
      {/* <Components.List options={[option1, option2, option3]} /> */}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;
const RecommendedTitle = styled.div`
  background-color: ${({ theme }) =>
    theme.Color.background.positive.default.value};
  border-radius: 4px;
  padding: 1px 8px;
  width: fit-content;
`;
