import { Components } from "@thisgorilla/primate-ds";
import styled from "styled-components";

export const JoinNowAndSignInButtons = () => {
  return (
    <Container>
      <Components.Button
        kind="Tertiary"
        color="Primary"
        size="Medium"
        disabled={false}
        label="Join Now"
        onClick={() => {}}
      />
      <Components.Button
        kind="Primary"
        color="Primary"
        size="Medium"
        disabled={false}
        label="Sign In"
        onClick={() => {
          // TODO: Add sign in logic
        }}
        iconPosition="left"
        iconType="LogIn"
      />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
`;
