import { Components } from "@thisgorilla/primate-ds";
import styled from "styled-components";

export const Notifications = () => {
  return (
    <Container>
      <Components.IconButton
        iconType={"Bell"}
        disabled={false}
        kind="Secondary"
        onClick={() => {}}
        size="Medium"
      />
      <Components.IconButton
        iconType={"MessageCircle"}
        disabled={false}
        kind="Secondary"
        onClick={() => {}}
        size="Medium"
      />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  gap: inherit;
`;
